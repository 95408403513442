import React, {FC} from 'react'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {StackScreenProps} from '@react-navigation/stack'

import {ShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import CreatePasswordBase from 'src/products/MCU/LoginTransition/CreatePasswordBase'
import {
  UserForgotPasswordResetInput,
  AccountUsernameType,
  LoginResult,
  VerificationCodeType,
} from '@possible/cassandra/src/types/types.public.generated'
import {userForgotPasswordReset} from '@possible/cassandra/src/user/authPublicMethods'

type Props = StackScreenProps<MainStackParamList, 'CreatePasswordLoggedOut'> & {
  username: string
  code: string
  obfuscatedEmail: string
  verificationCodeType: VerificationCodeType
}

const nextScreen = 'Login'

const CreatePasswordLoggedOut: FC<Props> = (props: Props) => {
  const {verificationCodeType, code, username, navigation, obfuscatedEmail} = props
  const onSubmit = async (password: string): Promise<void> => {
    try {
      const input: UserForgotPasswordResetInput = {
        usernameType: AccountUsernameType.Phone,
        username: username,
        verificationCode: code,
        password: password,
        verificationCodeType: verificationCodeType,
      }
      const resp: LoginResult | undefined = await userForgotPasswordReset(input)
      if (!resp) {
        throw Error('Failed to reset password')
      }
      navigation?.navigate(nextScreen)
    } catch (e) {
      ShowException(e)
    }
  }

  return <CreatePasswordBase email={obfuscatedEmail} submitAction={onSubmit} />
}

export default withForwardedNavigationParams<Props>()(CreatePasswordLoggedOut)
