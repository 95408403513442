import React from 'react'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {LoanApprovedPaymentType} from 'src/products/loans/LoanApprovedActivation/LoanPaymentReview/LoanPaymentReviewTemplate/LoanPaymentReviewContent/LoanPaymentReviewContent'
import {NamedColors} from 'src/designSystem/colors'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'

const getPaymentRows = (payments: LoanApprovedPaymentType[]): JSX.Element[] => {
  return payments?.map(
    (payment): JSX.Element => (
      <Box
        key={`payment-${Date.parse((payment.date ?? '').toString())}`}
        gap={'tiny'}
        direction={'row'}
        align={'center'}
      >
        <Box flex>
          <PFText variant={'p'}>{moment(payment.date).format('MMM DD')}</PFText>
        </Box>
        <PFText variant={'p_semibold'}></PFText>
        <Box padding={'little'} radius={'small'} background={NamedColors.WHITE}>
          <PFText variant={'p_semibold'} color={NamedColors.PRODUCT_BLUE}>
            ${payment.amount ?? '0'}
          </PFText>
        </Box>
      </Box>
    ),
  )
}

export type PaymentInfoTileProps = {
  payments: LoanApprovedPaymentType[]
  accountMask?: string
}

const PaymentInfoTile: React.FC<PaymentInfoTileProps> = (props) => {
  const {t} = useTranslation(['Advance', 'LoanPaymentReview'])
  const {payments} = props

  const paymentRows = getPaymentRows(payments)

  return (
    <BasicTile styles={{background: NamedColors.WHITESMOKE}}>
      <Box gap={'small'} direction={'column'} justify={'between'} padding={'small'}>
        <PFText variant={'p_semibold'}>{t('EightWeekPaymentPlan')}</PFText>
        <Box direction={'column'} justify={'between'} gap={'small'}>
          {paymentRows}
        </Box>
      </Box>
    </BasicTile>
  )
}

export {PaymentInfoTile}
