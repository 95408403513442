import React, {memo, useRef, useState} from 'react'
import {
  LayoutAnimation,
  LayoutAnimationConfig,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  ScrollView,
  StyleSheet,
  UIManager,
  View,
} from 'react-native'

import Log from 'src/lib/loggingUtil'
import {logContentCardClick} from 'src/lib/braze/braze'
import {BrazeContentCard} from 'src/lib/braze/braze.utils'
import {mediumGap, tinyGap} from 'src/designSystem/layout'
import {mapBrazeContentCardToComponent} from 'src/products/general/components/organisms/BrazeContentCards/BrazeContentCards.utils'

if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true)
}

type BrazeContentCardsProps = {
  brazeContentCardData: BrazeContentCard[]
  onDismiss: () => void
  isCardsLayout?: boolean
}

// Memoized to reduce rerender impact on `LayoutAnimation
const BrazeContentCards = memo(
  ({
    brazeContentCardData: brazeContentCards,
    onDismiss,
    isCardsLayout = false,
  }: BrazeContentCardsProps): JSX.Element => {
    const scrollViewRef = useRef<ScrollView>(null)
    const activeCardIndex = useRef(0)
    const [layoutWidth, setLayoutWidth] = useState<number>(0)

    const animationConfig: LayoutAnimationConfig = {
      duration: 300,
      create: {type: 'easeInEaseOut', property: 'opacity'},
      /* Animate height changes when adding/removing tiles. Tiles fill their container but may have
       * different amounts of content. */
      update: {type: 'easeInEaseOut', property: 'scaleY'},
      delete: {type: 'easeInEaseOut', property: 'opacity'},
    }

    const logAnimationError = (): void => Log.error('Error animating Braze Content Cards')

    const handleOnLayout = (event: LayoutChangeEvent): void => {
      LayoutAnimation.configureNext(animationConfig, undefined, logAnimationError)
      setLayoutWidth(event.nativeEvent.layout.width)
    }

    const handleOnMomentumScrollEnd = (event: NativeSyntheticEvent<NativeScrollEvent>): void => {
      const offsetX = event.nativeEvent.contentOffset.x
      const newIndex = Math.round(offsetX / layoutWidth)
      activeCardIndex.current = newIndex
    }

    const scrollToIndex = (index: number): void => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTo({
          x: index * layoutWidth,
          animated: true,
        })
      }
      activeCardIndex.current = index
    }

    const handleOnPress = (datum: BrazeContentCard): void => {
      logContentCardClick(datum)

      if (activeCardIndex.current < brazeContentCards.length - 1) {
        scrollToIndex(activeCardIndex.current + 1)
      } else {
        onDismiss() // Dismisses all Braze Content Cards.
        LayoutAnimation.configureNext(animationConfig, undefined, logAnimationError)
      }
    }

    return (
      <ScrollView
        contentContainerStyle={styles.contentContainerStyle}
        decelerationRate={'fast'}
        horizontal={true}
        onLayout={handleOnLayout}
        onMomentumScrollEnd={handleOnMomentumScrollEnd}
        ref={scrollViewRef}
        showsHorizontalScrollIndicator={false}
        snapToAlignment={'center'}
        snapToInterval={layoutWidth}
        style={brazeContentCards.length > 0 ? styles.itemShadowOffset : styles.emptyContent}
        testID={'BrazeContentCards'}
      >
        {brazeContentCards.map((datum, index) => {
          const MappedComponent = mapBrazeContentCardToComponent(datum, {
            onPress: () => handleOnPress(datum),
            // Hide Carousel pagination if only one component is rendered.
            index: brazeContentCards.length > 1 ? index + 1 : undefined,
            totalTiles: brazeContentCards.length,
          })

          return MappedComponent ? (
            <View
              key={datum.id}
              style={[
                styles.itemWrapper,
                {width: layoutWidth},
                isCardsLayout ? {paddingHorizontal: mediumGap} : {},
              ]}
            >
              {MappedComponent}
            </View>
          ) : null
        })}
      </ScrollView>
    )
  },
)

BrazeContentCards.displayName = 'BrazeContentCards'

const styles = StyleSheet.create({
  contentContainerStyle: {
    minWidth: '100%',
  },
  emptyContent: {
    marginVertical: -(mediumGap / 2), // Empty content gap fix.
    width: '100%', // Android LayoutAnimation fix.
  },
  itemShadowOffset: {marginVertical: -mediumGap},
  itemWrapper: {
    flex: 1,
    paddingVertical: mediumGap, // Item shadow fix.
    paddingHorizontal: tinyGap / 2,
  },
})

export {BrazeContentCards}
export type {BrazeContentCardsProps}
