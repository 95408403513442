import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'

export type MembershipStartDateTileProps = {
  startDate: string
}

const MembershipStartDateTile: React.FC<MembershipStartDateTileProps> = (props) => {
  const {startDate} = props
  const {t} = useTranslation(['Advance'])

  return (
    <BasicTile styles={{background: NamedColors.WHITESMOKE}}>
      <Box gap={'small'} direction={'column'} justify={'between'} padding={'small'}>
        <PFText variant={'p_semibold'}>{t('MonthlyMembershipStartDate')}</PFText>
        <Box direction={'row'} justify={'between'} align={'center'}>
          <PFText variant={'p'}>{moment(startDate).format('MMM DD')}</PFText>
          <Box padding={'little'} radius={'small'} background={NamedColors.WHITE}>
            <PFText variant={'p_semibold'} color={NamedColors.PRODUCT_BLUE}>
              $15.00
            </PFText>
          </Box>
        </Box>
      </Box>
    </BasicTile>
  )
}

export {MembershipStartDateTile}
