import React, {FC, ReactNode, useMemo} from 'react'
import {View} from 'react-native'
import {ErrorBoundary} from 'react-error-boundary'

import {smallGap} from 'src/designSystem/layout'
import {BrazeContentCardScreen} from 'src/lib/braze/braze.utils'
import {BrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/BrazeContentCards'
import {useBrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards'
import {
  filterBrazeContentCardsByScreenAndLocation,
  FilterBrazeContentCardsByScreenAndLocationProps,
} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards.utils'
import type {BrazeContentCard} from 'src/lib/braze/braze.utils'
import {loanDashboardLogError} from 'src/products/loans/Dashboard/DashboardLoanUtils/DashboardLoan.utils'

type Props = {location: BrazeContentCard['extras']['location']; includeMarginTop?: boolean}

/**
 * LoanBrazeTiles lets us use the Braze tool to show dynamic content to users
 * @example <LoanBrazeTiles location={2} includeMarginTop />
 */
const LoanBrazeTiles: FC<Props> = ({location, includeMarginTop = false}): ReactNode => {
  const brazeLoanDashboardLocation = useMemo(
    () =>
      filterBrazeContentCardsByScreenAndLocation({
        location,
        screenName: BrazeContentCardScreen.LoansDashboard,
      }),
    [location],
  )

  const {brazeContentCards, handleOnDismiss} =
    useBrazeContentCards<FilterBrazeContentCardsByScreenAndLocationProps>(
      brazeLoanDashboardLocation,
    )

  return brazeContentCards.length > 0 ? (
    <ErrorBoundary
      fallback={null}
      onError={(err: Error): void => {
        loanDashboardLogError(err, 'Braze Content Cards failed to render')
      }}
    >
      <View style={{marginTop: includeMarginTop ? smallGap : 0}}>
        <BrazeContentCards brazeContentCardData={brazeContentCards} onDismiss={handleOnDismiss} />
      </View>
    </ErrorBoundary>
  ) : null
}

export {LoanBrazeTiles}
