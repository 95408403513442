import {useCallback, useEffect, useState} from 'react'

import {checkPushPermissions} from 'src/lib/usePushPermissions/checkPushPermissions/checkPushPermissions'
import {requestPushPermissions as requestNativePermissions} from 'src/lib/usePushPermissions/requestPushPermissions/requestPushPermissions'
import Log from 'src/lib/loggingUtil'

export type UsePushPermissions = [boolean, () => Promise<boolean>, boolean]

/**
 * A stateful hook that returns the current push notification permissions
 * and a function to request them
 * @returns A tuple of (do we have push permissions already?, request them from the user, are they done initializing?)
 * [boolean, async () => boolean, boolean]
 */
export const usePushPermissions = (): UsePushPermissions => {
  const [hasPushPermissions, setHasPushPermissions] = useState(false)
  const [isInitializing, setIsInitializing] = useState(true)

  useEffect(() => {
    const fetchCurrentPermissions = async (): Promise<void> => {
      setHasPushPermissions(await checkPushPermissions())
      setIsInitializing(false)
    }

    fetchCurrentPermissions().catch((error) => {
      Log.log('Error fetching push permissions on mount', error)
    })
  }, [])

  const requestPushPermissions = useCallback(async () => {
    await requestNativePermissions()

    const hasPushPermissions = await checkPushPermissions()
    setHasPushPermissions(hasPushPermissions)

    return hasPushPermissions
  }, [])

  return [hasPushPermissions, requestPushPermissions, isInitializing]
}
