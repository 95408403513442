import React, {FC, ReactNode} from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'

import {smallGap} from 'src/designSystem/layout'

export type ReactNodeWithStyle = {
  element: ReactNode
  style: StyleProp<ViewStyle>
}

type TileItemProps = {
  item: ReactNode | ReactNodeWithStyle
  index: number
}

type TileListViewProps = {
  list: (ReactNode | ReactNodeWithStyle)[]
  testID?: string
}

const isReactNodeWithStyle = (item: ReactNode | ReactNodeWithStyle): item is ReactNodeWithStyle => {
  return !!(item && item instanceof Object && 'style' in item)
}

const TileItem: FC<TileItemProps> = ({item, index}) => {
  if (isReactNodeWithStyle(item)) {
    return <View style={[styles.tileItem, item.style]}>{item.element}</View>
  }
  const marginTop = index === 0 ? 0 : smallGap
  return <View style={[styles.tileItem, {marginTop}]}>{item}</View>
}

const TileListView: FC<TileListViewProps> = ({list, testID}) => {
  const tiles = list.map((item, index) => (
    <TileItem item={item} key={`tile-item-${index + 1}`} index={index} />
  ))

  return (
    <View testID={testID} style={styles.listView}>
      {tiles}
    </View>
  )
}

const styles = StyleSheet.create({
  listView: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tileItem: {
    width: '100%',
  },
})

export default TileListView
