import React, {ReactElement, ReactNode, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {HowAutoPayWorksOverlay} from 'src/products/MCU/AccountManagementV2/PaymentMethods/HowAutoPayWorksOverlay/HowAutoPayWorksOverlay'
import {PFStatusPill} from 'src/designSystem/components/atoms/PFStatusPill/PFStatusPill'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {Touchable} from 'src/designSystem/components/atoms/Touchable/Touchable'
import Box from 'src/designSystem/components/atoms/Box/Box'
import DualLineText from 'src/designSystem/components/molecules/DualLineText/DualLineText'

const warningIcon: JSX.Element = (
  <SvgIcon
    testID="Bank-Account-Tile-Warning-Icon"
    name={'warning'}
    colorVariant={'warning'}
    size={'medium'}
    isFilled
  />
)

const editIcon: JSX.Element = (
  <SvgIcon
    testID="Bank-Account-Tile-Edit-Icon"
    name={'edit'}
    colorVariant={'info'}
    size={'medium'}
  />
)

export type BankAccountTileContentProps = {
  accountName: string
  accountNumber: string
  icon?: 'edit' | 'warning'
  wasAccountJustAdded?: boolean
  isDefaultLoanPaymentMethod?: boolean
  isPrimaryAccount?: boolean
}

const BankAccountTileContent: React.FC<BankAccountTileContentProps> = ({
  accountName,
  accountNumber,
  icon,
  wasAccountJustAdded,
  isDefaultLoanPaymentMethod,
  isPrimaryAccount,
}: BankAccountTileContentProps): ReactElement => {
  const {t} = useTranslation(['Bank'])
  const [isHowAutoPayWorksVisible, setIsHowAutoPayWorksVisible] = useState<boolean>(false)

  const handleOnPressHowAutoPayWorks = (): void => {
    setIsHowAutoPayWorksVisible(true)
  }

  const handleOnDismissHowAutoPayWorks = (): void => {
    setIsHowAutoPayWorksVisible(false)
  }

  const getIcon = icon === 'warning' ? warningIcon : icon === 'edit' ? editIcon : null

  let statusPill: ReactNode = null

  if (isDefaultLoanPaymentMethod) {
    statusPill = (
      <Touchable onPress={handleOnPressHowAutoPayWorks}>
        <PFStatusPill
          icon={{
            colorVariant: 'info',
            name: 'moneyRotating',
          }}
          iconPlacement="before"
          text={t('AccountTagAutoPay')}
          variant="outline"
        />
      </Touchable>
    )
  } else if (wasAccountJustAdded) {
    statusPill = <PFStatusPill variant="outline" text={t('AccountTagNew')} />
  }
  return (
    <>
      <Box testID="Bank-Account-Tile-Content" flex direction={'row'} align={'center'}>
        <Box marginRight={'small'}>
          <SvgIcon name={'bank'} colorVariant={'default'} size={'medium'} />
        </Box>
        <Box flex testID={isPrimaryAccount ? 'BankAccountTile-PrimaryAccount' : undefined}>
          {statusPill}
          <DualLineText text={accountName} subText={accountNumber} />
        </Box>
        <Box marginLeft={'small'}>{getIcon}</Box>
      </Box>
      <HowAutoPayWorksOverlay
        isVisible={isHowAutoPayWorksVisible}
        onDismiss={handleOnDismissHowAutoPayWorks}
      />
    </>
  )
}

export {BankAccountTileContent}
