import React, {FC} from 'react'
import {StyleSheet} from 'react-native'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type TilaLineItemProps = {
  title: string
  body: string
  amount: string
}

const TilaLineItem: FC<TilaLineItemProps> = (props) => {
  const {title, body, amount} = props
  return (
    <Box>
      <Box width={amount ? '80%' : '100%'}>
        <PFText variant={'p_sm_semibold'}>{title}</PFText>
        <PFText variant={'p_sm'}>{body}</PFText>
      </Box>
      <Box boxStyle={styles.itemAmountText} width={amount ? '20%' : undefined} align={'end'}>
        <PFText variant={'p_sm_semibold'} color="info">
          {amount}
        </PFText>
      </Box>
    </Box>
  )
}

type AprTilaLineItemProps = {
  apr: string
}
const AprTilaLineItem: FC<AprTilaLineItemProps> = (props) => {
  const {apr} = props
  const {t} = useTranslation('LoanApproved')
  return (
    <TilaLineItem title={t('AnnualPercentageRate')} body={t('APRDescription')} amount={`${apr}%`} />
  )
}

type FinanceChargeTilaLineItemProps = {
  totalCost: string
}
const FinanceChargeTilaLineItem: FC<FinanceChargeTilaLineItemProps> = (props) => {
  const {totalCost} = props
  const {t} = useTranslation('LoanApproved')
  return (
    <TilaLineItem
      title={t('FinanceCharge')}
      body={t('FinanceChargeDescription')}
      amount={`$${totalCost}`}
    />
  )
}

type AmountFinancedTilaLineItemProps = {
  amountApproved: string
}
const AmountFinancedTilaLineItem: FC<AmountFinancedTilaLineItemProps> = (props) => {
  const {amountApproved} = props
  const {t} = useTranslation('LoanApproved')
  return (
    <TilaLineItem
      title={t('AmountFinanced')}
      body={t('AmountFinancedDescription')}
      amount={`$${amountApproved}`}
    />
  )
}

type TotalOfPaymentsTilaLineItemProps = {
  totalOwed: string
}
const TotalOfPaymentsTilaLineItem: FC<TotalOfPaymentsTilaLineItemProps> = (props) => {
  const {totalOwed} = props
  const {t} = useTranslation('LoanApproved')
  return (
    <TilaLineItem
      title={t('TotalOfPayments')}
      body={t('TotalOfPaymentsDescription')}
      amount={`$${totalOwed}`}
    />
  )
}

const PaymentScheduleTilaLineItem: FC = () => {
  const {t} = useTranslation('LoanApproved')
  return (
    <TilaLineItem title={t('PaymentSchedule')} body={t('PaymentScheduleDescription')} amount={''} />
  )
}

export {
  AprTilaLineItem,
  FinanceChargeTilaLineItem,
  AmountFinancedTilaLineItem,
  TotalOfPaymentsTilaLineItem,
  PaymentScheduleTilaLineItem,
}

const styles = StyleSheet.create({
  itemAmountText: {
    position: 'absolute',
    right: 0,
  },
})
