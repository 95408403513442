import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {createStackNavigator, StackNavigationProp, StackScreenProps} from '@react-navigation/stack'
import {useFocusEffect} from '@react-navigation/native'
import {difference} from 'lodash'

import {
  GetCurrentRouteName,
  GetOfferApplicationWorkflowNavigationOptions,
  SelectedOfferPreReqsNotMetSelectorFactory,
  useSetupRouteHistory,
} from 'src/workflows/navigation'
import {
  OfferApplicationWorkflowStackParams,
  WorkflowsReadyType,
  WorkflowsStackParams,
} from 'src/workflows/types'
import {AcceptPrimaryAccountAgreement} from 'src/products/MCU/AccountManagementV2/AcceptPrimaryAccountAgreement/AcceptPrimaryAccountAgreement'
import {AddBankLinkWorkflowContainer} from 'src/products/general/AddBankLink/AddBankLinkWorkflowContainer'
import {AggregatorPlaidFlagRouter} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/AggregatorPlaidFlagRouter'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {BankAggregatorAccountSelectionForPrimaryAccount} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorAccountSelectionForPrimaryAccount/BankAggregatorAccountSelectionForPrimaryAccount'
import {CardApplicationFinancesWorkflowContainer} from 'src/products/card/Application/CardApplicationFinances/CardApplicationFinancesWorkflowContainer'
import {ClearSelectedOfferAction} from 'src/workflows/slice'
import {EmailVerificationWorkflowContainer} from 'src/products/loans/EmailVerification/EmailVerificationWorkflowContainer'
import {GetNextRouteFromPreReqs, GetSortedPreReqs} from 'src/workflows/workflow.utils'
import {LoanAmountSelectionWorkflowContainer} from 'src/products/loans/LoanAmountSelection/LoanAmountSelectionWorkflowContainer'
import {LoanApplicationSubmissionWorkflowContainer} from 'src/products/loans/LoanApplicationSubmission/LoanApplicationSubmissionWorkflowContainer'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {MarketingSurveyWorkflowsContainer} from 'src/products/general/MarketingSurvey/MarketingSurveyWorkflowsContainer'
import {PrimaryAccountSelectionWorkflowContainer} from 'src/products/MCU/AccountManagementV2/PrimaryAccountSelection/PrimaryAccountSelectionWorkflowContainer'
import {ReapplicationBankWorkflowContainer} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/ReapplicationBank/ReapplicationBankWorkflowContainer'
import {sortSelector} from 'src/products/general/OfferApplicationWorkflow/OfferApplicationWorkflowSorting'
import {SSNCollectionWorkflowContainer} from 'src/products/loans/PersonalInformation/SSNCollection/SSNCollectionWorkflowContainer'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'
import {useFrontEndPreReqs} from 'src/workflows/hooks/useFrontEndPreReqs'
import {useMoveToNextPreReqRoute} from 'src/workflows/hooks/useMoveToNextPreReqRoute'
import {usePfDispatch} from 'src/store/utils'
import {useWorkflowsSort} from 'src/workflows/experimentation'
import {wfLog, wfWarn} from 'src/workflows/logging'
import {WorkflowLoading} from 'src/products/general/Workflows/WorkflowLoading'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import CardAgreementViewerWorkflowContainer from 'src/products/card/Application/CardAgreementViewer/CardAgreementViewerWorkflowContainer'
import PersonalInfoConfirmationWorkflowContainer from 'src/products/general/PersonalInfoConfirmation/PersonalInfoConfirmationWorkflowContainer'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {CollectIDStateNVWorkflowContainer} from 'src/products/general/CollectIDStateNV/CollectIDStateNVWorkflowContainer'

const OfferApplicationWorkflowStackNav = createStackNavigator<OfferApplicationWorkflowStackParams>()

const OfferApplicationWorkflowReady: React.FC<
  StackScreenProps<MainStackParamList, 'OfferApplicationWorkflow'> & WorkflowsReadyType
> = (props) => {
  const {selectedOffer, refetch, navigation} = props

  const moveToNextPreReqRoute = useMoveToNextPreReqRoute({
    met: selectedOffer?.metPreReqs ?? [],
    unmet: selectedOffer?.unmetPreReqs ?? [],
    workflowName: 'OfferApplication',
  })

  const {canAccessCards: isCardsUser, isLoading: isLoadingIfUserCanAccessCards} =
    useCanAccessCards()

  const [workflowNavigation, setWorkflowNavigation] =
    useState<StackNavigationProp<WorkflowsStackParams>>()

  const dispatch = usePfDispatch()

  useWorkflowsSort({
    selectedOffer,
    unmetPreReqs: selectedOffer?.unmetPreReqs ?? [],
    preReqSortSelector: sortSelector,
    passthrough: {
      isLoanOnboarding: !isCardsUser,
    },
  })

  useSetupRouteHistory({
    navigation: workflowNavigation,
    unmetPreReqs: selectedOffer?.unmetPreReqs ?? [],
    metPreReqs: selectedOffer?.metPreReqs ?? [],
    selectedOffer: selectedOffer ?? undefined,
  })

  const focusEffect = useCallback(() => {
    // if the user backs out of the workflow we should clear the selected offer
    // going back will not be an option for initial applicants
    const listener: Parameters<typeof navigation.addListener>[1] = (event) => {
      if (event.data && 'action' in event.data && event.data?.action.type === 'GO_BACK') {
        void dispatch(ClearSelectedOfferAction())
      }
    }

    navigation.addListener('beforeRemove', listener)
    return (): void => navigation.removeListener('beforeRemove', listener)
  }, [dispatch, navigation])

  useFocusEffect(focusEffect)

  const visibleRoute = GetCurrentRouteName(navigation.getState())

  const currentRouteName = useMemo(
    () => GetNextRouteFromPreReqs(selectedOffer?.unmetPreReqs ?? []),
    [selectedOffer?.unmetPreReqs],
  )
  wfLog('Current route name: ', currentRouteName)

  const {onPreReqsFulfilled: onFrontEndPreReqsFulfilled} = useFrontEndPreReqs(visibleRoute)

  useEffect(() => {
    TrackAppEvent(AppEvents.Name.offer_application_workflow_entered, AppEvents.Category.Workflows, {
      route: currentRouteName,
    })
    return (): void => {
      TrackAppEvent(AppEvents.Name.offer_application_workflow_exited, AppEvents.Category.Workflows)
    }
    // we don't want to track this on every render; just on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnPreReqFulfilled = useCallback(async () => {
    const fulfilledFrontEndPreReqs = await onFrontEndPreReqsFulfilled()

    // just a check to make typescript happy
    // we shouldn't ever get here without a selectedOffer.offerId
    if (!selectedOffer?.offerId) {
      wfWarn('handleOnPreReqFulfilled called without selectedOffer.offerId')
      return
    }

    const diff = difference(selectedOffer.unmetFrontEndPreReqs, fulfilledFrontEndPreReqs)
    await moveToNextPreReqRoute({
      navigation,
      refetch,
      preReqSelector: SelectedOfferPreReqsNotMetSelectorFactory(selectedOffer.offerId, diff),
      selectedOffer,
      onAllPreReqsMet: async () => {
        try {
          await dispatch(ClearSelectedOfferAction())
        } catch (e) {
          logOfferApplicationError(e, 'onAllPreReqsMet ClearSelectedOfferAction failed')
        }
        return false
      },
    })
  }, [
    dispatch,
    moveToNextPreReqRoute,
    navigation,
    onFrontEndPreReqsFulfilled,
    refetch,
    selectedOffer,
  ])

  const handleOnNavigationLoaded = useCallback((nav: StackNavigationProp<WorkflowsStackParams>) => {
    setWorkflowNavigation(nav)
  }, [])

  if (isLoadingIfUserCanAccessCards) {
    return <Spinner />
  }

  return (
    <>
      <OfferApplicationWorkflowStackNav.Navigator
        initialRouteName="Loading"
        screenOptions={{
          gestureEnabled: true,
          headerTransparent: true,
          headerShadowVisible: false,
          headerBackgroundContainerStyle: {
            backgroundColor: 'white',
          },
          headerMode: 'float',
        }}
      >
        <OfferApplicationWorkflowStackNav.Screen
          name="Loading"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <WorkflowLoading {...screenProps} onNavigationLoaded={handleOnNavigationLoaded} />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="SSN"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <SSNCollectionWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="PIIConfirm"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <PersonalInfoConfirmationWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="LoanAmountSelection"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => {
            return (
              <LoanAmountSelectionWorkflowContainer
                {...screenProps}
                onPreReqFulfilled={handleOnPreReqFulfilled}
              />
            )
          }}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="BankLinking"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <AddBankLinkWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="CollectIDStateNV"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CollectIDStateNVWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="PrimaryAccountSelection"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <PrimaryAccountSelectionWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="ReapplicationBankConfirmation"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <ReapplicationBankWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="MarketingSurvey"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <MarketingSurveyWorkflowsContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>

        <OfferApplicationWorkflowStackNav.Screen
          name="EmailVerification"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <EmailVerificationWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="LoanApplicationSubmission"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <LoanApplicationSubmissionWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>

        {/* Cards Specific */}
        <OfferApplicationWorkflowStackNav.Screen
          name="CardApplicationFinances"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CardApplicationFinancesWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>
        <OfferApplicationWorkflowStackNav.Screen
          name="CardApplicationSubmission"
          options={GetOfferApplicationWorkflowNavigationOptions}
        >
          {(screenProps): React.ReactNode => (
            <CardAgreementViewerWorkflowContainer
              {...screenProps}
              onPreReqFulfilled={handleOnPreReqFulfilled}
            />
          )}
        </OfferApplicationWorkflowStackNav.Screen>

        {/* Banking Screens */}
        <OfferApplicationWorkflowStackNav.Screen
          name="AggregatorPlaid"
          // eslint-disable-next-line no-type-assertion/no-type-assertion
          component={AggregatorPlaidFlagRouter as unknown as React.FC}
          options={GetOfferApplicationWorkflowNavigationOptions}
        />
        <OfferApplicationWorkflowStackNav.Screen
          name="BankAggregatorAccountSelectionForPrimaryAccount"
          component={BankAggregatorAccountSelectionForPrimaryAccount}
          options={GetOfferApplicationWorkflowNavigationOptions}
        />
        <OfferApplicationWorkflowStackNav.Screen
          name="AcceptPrimaryAccountAgreement"
          component={AcceptPrimaryAccountAgreement}
          options={GetOfferApplicationWorkflowNavigationOptions}
        />
      </OfferApplicationWorkflowStackNav.Navigator>
      <ReadyDebug {...props} />
    </>
  )
}

// Let's come back to this later
// eslint-disable-next-line import/no-default-export
export default OfferApplicationWorkflowReady

const ReadyDebug: React.FC<
  StackScreenProps<MainStackParamList, 'OfferApplicationWorkflow'> & WorkflowsReadyType
> = (props) => {
  const {selectedOffer, navigation} = props

  const [isVisible, setIsVisible] = useState(__DEV__)

  const handleOnPress = useCallback(() => {
    setIsVisible((prev) => !prev)
  }, [])

  const nextRoute = useMemo(
    () => GetNextRouteFromPreReqs(selectedOffer?.unmetPreReqs ?? []),
    [selectedOffer?.unmetPreReqs],
  )
  const sortedPreReqs = useMemo(
    () => GetSortedPreReqs(selectedOffer?.unmetPreReqs ?? []),
    [selectedOffer?.unmetPreReqs],
  )

  const navState = navigation.getState()

  const details = useMemo(() => {
    if (!isVisible) {
      return null
    }

    return (
      <>
        <PFText variant="p_sm" color="inverse">
          Offer ID: {selectedOffer?.offerId}
        </PFText>
        {sortedPreReqs.length > 0 ? (
          <PFText variant="p_sm" color="inverse">
            Current PreReq: {sortedPreReqs[0]}
          </PFText>
        ) : null}
        <PFText variant="p_sm" color="inverse">
          Current Route: {nextRoute}
        </PFText>
        <PFText variant="p_sm" color="inverse">
          Visible Route: {GetCurrentRouteName(navState)}
        </PFText>
      </>
    )
  }, [navState, nextRoute, selectedOffer?.offerId, isVisible, sortedPreReqs])

  if (!__DEV__) {
    return null
  }

  return (
    <Box
      align="center"
      justify="center"
      gap="little"
      // disabling this warning as this debug component is only used in dev
      // and will be removed in the future

      boxStyle={{position: 'absolute', opacity: isVisible ? 0.8 : 0.3, top: 40, right: 10}}
      background="black"
      radius="small"
      padding="little"
    >
      <PFText variant="label_sm" color="inverse">
        Offer Application Workflow Debug
      </PFText>
      {details}
      <Button mode="progressive" size="small" onPress={handleOnPress}>
        {isVisible ? 'Hide' : 'Show More'}
      </Button>
    </Box>
  )
}

export {OfferApplicationWorkflowStackNav}
