import React from 'react'
import {StackScreenProps} from '@react-navigation/stack'

import {AppEvents} from 'src/lib/Analytics/app_events'
import {ApplicationStepsTemplate} from 'src/products/general/ApplicationSteps/ApplicationStepsTemplate'
import {ApplyInFewStepsTemplate} from 'src/products/general/ApplyInFewSteps/ApplyInFewStepsTemplate'
import {getIsAppRevampFunctionalUpdatesEnabled} from 'src/lib/experimentation/appRevampFeatureFlag'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {usePushPermissions} from 'src/lib/usePushPermissions'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

type ApplicationStepsContainerProps = WorkflowPreReqFulfillScreenProps &
  StackScreenProps<MainStackParamList, 'ApplicationSteps'>

const ApplicationStepsContainer: React.FC<ApplicationStepsContainerProps> = (
  props,
): JSX.Element => {
  const {navigation, onPreReqFulfilled} = props
  const [hasPushPermissions] = usePushPermissions()
  const isAppRevampFunctionalUpdatesFFEnabled = getIsAppRevampFunctionalUpdatesEnabled()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.application_steps_screen_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  navigation.setOptions({
    headerShown: false,
  })

  const handleOnContinue = async (): Promise<void> => {
    if (isDeviceWeb() || isAppRevampFunctionalUpdatesFFEnabled) {
      await onPreReqFulfilled()
      return
    }

    if (!hasPushPermissions) {
      navigation.navigate('NotificationsPermissions')
      return
    }

    await onPreReqFulfilled()
  }

  if (getIsAppRevampFunctionalUpdatesEnabled()) {
    return <ApplicationStepsTemplate onContinue={handleOnContinue} />
  }

  return <ApplyInFewStepsTemplate onContinue={handleOnContinue} />
}

export {ApplicationStepsContainer}
