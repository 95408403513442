import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UserIdInfoAddMutationVariables = Types.Exact<{
  govtIssuedIdNumber: Types.Scalars['String']['input']
  type: Types.UserIdInfoType
  issuingState: Types.StateCodes
}>

export type UserIdInfoAddMutation = {__typename?: 'Mutation'; userIdInfoAdd: boolean}

export const UserIdInfoAddDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserIdInfoAdd'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'govtIssuedIdNumber'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserIdInfoType'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'issuingState'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'StateCodes'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userIdInfoAdd'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'input'},
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'govtIssuedIdNumber'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'govtIssuedIdNumber'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'type'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
                    },
                    {
                      kind: 'ObjectField',
                      name: {kind: 'Name', value: 'issuingState'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'issuingState'}},
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserIdInfoAddMutation, UserIdInfoAddMutationVariables>
