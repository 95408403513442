import {useNavigation} from '@react-navigation/native'
import React, {useCallback} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {LegalCheckbox} from 'src/designSystem/components/molecules/LegalCheckbox/LegalCheckbox'
import {OpenWebPage} from 'src/navigation/NavHelper'
import {possibleLMembershipURI} from 'src/navigation/WebLinks'
import {getSubscriptionAutoPayAgreementDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useGetAgreementDocument/useGetAgreementDocument'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'

export type SubscriptionLegalizeProps = {
  onSetSelected: (value: boolean) => void
  loanId: string
  onCloseBottomSheet?: () => void
}

export const SubscriptionLegalize: React.FC<SubscriptionLegalizeProps> = (props) => {
  const {onSetSelected: handleSetSelected, loanId, onCloseBottomSheet} = props

  const navigation = useNavigation()

  const handleMembershipAgreementLink = useCallback(() => {
    OpenWebPage({uri: possibleLMembershipURI()})
  }, [])

  const handlePaymentAuthLink = useCallback(async () => {
    const {documentUrl} = await getSubscriptionAutoPayAgreementDocument(loanId)
    displayPdf(documentUrl, navigation)
    onCloseBottomSheet?.()
  }, [loanId, navigation, onCloseBottomSheet])

  const {t} = useTranslation('Advance')
  return (
    <Box marginTop={'little'} paddingHorizontal={'small'}>
      <LegalCheckbox onPress={handleSetSelected}>
        <PFText variant="p">
          <Trans
            i18nKey={'AgreementText'}
            t={t}
            components={{
              MembershipAgreementLink: (
                <SvgLink
                  onPress={handleMembershipAgreementLink}
                  linkText={'Membership Agreement'}
                  linkType={'inline'}
                />
              ),
              PaymentAuthLink: (
                <SvgLink
                  onPress={handlePaymentAuthLink}
                  linkText={'Autopay Authorization Agreement'}
                  linkType={'inline'}
                />
              ),
            }}
          />
        </PFText>
      </LegalCheckbox>
    </Box>
  )
}
