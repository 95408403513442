import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

const PaymentDisclosure: React.FC = () => {
  const {t} = useTranslation(['DisbursementMethodSelection'])

  return (
    <Box direction={'row'} marginTop={'small'}>
      <Box marginRight={'small'}>
        <SvgIcon name={'calendar'} colorVariant={'disabled'} size={'large'} />
      </Box>
      <Box shrink={1}>
        <PFText variant="p_sm" color={'textDisabled'} textAlign={'justify'}>
          {t('AllOfYourPayments')}
        </PFText>
      </Box>
    </Box>
  )
}

export {PaymentDisclosure}
