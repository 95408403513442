// In an effort to keep this organized, it's broken up by category and then alphabetized. Subcategories are noted in comments within categories.
// A category is required when using TrackAppEvent, so please confirm a category with Product before adding a new event to ensure it's used consistently across the app.

export const ActivationEvents = {
  accept_agreements_accept_ach_selected: 'accept_agreements_accept_ach_selected',
  accept_agreements_accept_loan_agreement_selected:
    'accept_agreements_accept_loan_agreement_selected',
  accept_agreements_completed: 'accept_agreements_completed',
  accept_agreements_not_accept_ach_selected: 'accept_agreements_not_accept_ach_selected',
  accept_agreements_screen_viewed: 'accept_agreements_screen_viewed',
  accept_agreements_view_ach_agreement_selected: 'accept_agreements_view_ach_agreement_selected',
  accept_agreements_view_loan_agreement_selected: 'accept_agreements_view_loan_agreement_selected',
  accept_agreements_view_privacy_policy_selected: 'accept_agreements_view_privacy_policy_selected',
  accept_agreements_view_state_licenses_selected: 'accept_agreements_view_state_licenses_selected',
  accept_agreements_view_tos_selected: 'accept_agreements_view_tos_selected',
  completed_application_submitted: 'completed_application_submitted',
  conditions_for_approval_disclosure_selected: 'conditions_for_approval_disclosure_selected',
  confirm_bank_account_completed: 'confirm_bank_account_completed',
  confirm_bank_account_viewed: 'confirm_bank_account_viewed',
  confirm_debit_card_screen_viewed: 'confirm_debit_card_screen_viewed',
  customer_satisfaction_survey_leave_a_review_selected:
    'customer_satisfaction_survey_leave_a_review_selected',
  customer_satisfaction_survey_maybe_later_selected:
    'customer_satisfaction_survey_maybe_later_selected',
  customer_satisfaction_survey_screen_viewed: 'customer_satisfaction_survey_screen_viewed',
  disbursement_method_confirmed: 'disbursement_method_confirmed',
  disbursement_method_debit_link_fail: 'disbursement_method_debit_link_fail',
  disbursement_method_debit_link_success: 'disbursement_method_debit_link_success',
  disbursement_method_selected: 'disbursement_method_selected',
  disbursement_rtp_sheet_viewed: 'disbursement_rtp_sheet_viewed',
  enter_phone_number_completed: 'enter_phone_number_completed',
  enter_phone_number_screen_viewed: 'enter_phone_number_screen_viewed',
  enter_verification_code_contact_us_selected: 'enter_verification_code_contact_us_selected',
  enter_verification_code_phone_call_selected: 'enter_verification_code_phone_call_selected',
  enter_verification_code_resend_selected: 'enter_verification_code_resend_selected',
  enter_verification_code_screen_viewed: 'enter_verification_code_screen_viewed',
  enter_verification_code_submitted: 'enter_verification_code_submitted',
  final_loan_acceptance_completed: 'final_loan_acceptance_completed',
  final_loan_acceptance_failed: 'final_loan_acceptance_failed',
  final_loan_acceptance_screen_viewed: 'final_loan_acceptance_screen_viewed',
  how_payments_work_completed: 'how_payments_work_completed',
  how_payments_work_screen_viewed: 'how_payments_work_screen_viewed',
  loan_agreement_selected: 'loan_agreement_selected',
  loan_approved_completed: 'loan_approved_completed',
  loan_approved_screen_viewed: 'loan_approved_screen_viewed',
  loan_counter_offer_accepted: 'loan_counter_offer_accepted',
  loan_counter_offer_completed: 'loan_counter_offer_completed',
  loan_counter_offer_declined: 'loan_counter_offer_declined',
  loan_counter_offer_screen_viewed: 'loan_counter_offer_screen_viewed',
  loan_disbursement_method_selection_screen_viewed:
    'loan_disbursement_method_selection_screen_viewed',
  loan_not_approved_screen_viewed: 'loan_not_approved_screen_viewed',
  non_ach_option_agree_to_delay_of_funds_selected:
    'non_ach_option_agree_to_delay_of_funds_selected',
  non_ach_option_nevermind_selected: 'non_ach_option_nevermind_selected',
  purpose_for_loan_screen_viewed: 'purpose_for_loan_screen_viewed',
  purpose_for_loan_submitted: 'purpose_for_loan_submitted',
  relink_preferred_account_screen_completed: 'relink_preferred_account_screen_completed',
  relink_preferred_account_screen_viewed: 'relink_preferred_account_screen_viewed',
  review_payment_schedule_screen_completed: 'review_payment_schedule_screen_completed',
  review_payment_schedule_screen_viewed: 'review_payment_schedule_screen_viewed',
  suggested_debit_card_confirmed: 'suggested_debit_card_confirmed',
  tila_disclosure_screen_completed: 'tila_disclosure_screen_completed',
  tila_disclosure_screen_viewed: 'tila_disclosure_screen_viewed',
  your_money_is_on_the_way_completed: 'your_money_is_on_the_way_completed',
  your_money_is_on_the_way_screen_viewed: 'your_money_is_on_the_way_screen_viewed',

  // Email
  enter_email_verification_code_resend_selected: 'enter_email_verification_code_resend_selected',
  enter_email_verification_code_screen_viewed: 'enter_email_verification_code_screen_viewed',
  enter_email_verification_code_submitted: 'enter_email_verification_code_submitted',
  email_verification_failed: 'email_verification_failed',

  // Debit card flow
  add_debit_card_confirmed: 'add_debit_card_confirmed',

  // Upgrade to installment
  installment_upgrade_declined_modal_back: 'installment_upgrade_declined_modal_back',
  installment_upgrade_declined: 'installment_upgrade_declined',
  installment_upgrade_screen_viewed: 'installment_upgrade_screen_viewed',
} as const

export const AdminEvents = {
  address_update_completed: 'address_update_completed',
  address_update_viewed: 'address_update_viewed',
  contact_us_screen_viewed: 'contact_us_screen_viewed',
  cs_email_verify_click_log_in: 'cs_email_verify_click_log_in',
  cs_email_verify_confirmed: 'cs_email_verify_confirmed',
  cs_email_verify_failed: 'cs_email_verify_failed',
  cs_email_verify_try_again: 'cs_email_verify_try_again',
  edit_email_address_selected: 'edit_email_address_selected',
  edit_email_screen_viewed: 'edit_email_screen_viewed',
  edit_email_submitted: 'edit_email_submitted',
  edit_password_screen_viewed: 'edit_password_screen_viewed',
  edit_password_selected: 'edit_password_selected',
  edit_password_submitted: 'edit_password_submitted',
  profile_send_email_verification_code: 'profile_send_email_verification_code',
  help_center_selected: 'help_center_selected',
  help_center_redirect_viewed: 'help_center_redirect_viewed',
  help_center_redirect_selected: 'help_center_redirect_selected',
  notification_permission_screen_viewed: 'notification_permission_screen_viewed',
  notification_permission_granted_cta: 'notification_permission_granted_cta',
  notification_permission_not_now_cta: 'notification_permission_not_now_cta',
} as const

const ApplicationEvents = {
  completed_application_submission_screen_viewed: 'completed_application_submission_screen_viewed',
  front_id_photo_confirmation_screen_viewed: 'front_id_photo_confirmation_screen_viewed',
  front_id_photo_orientation_changed: 'front_id_photo_orientation_changed',
  front_id_photo_retaken: 'front_id_photo_retaken',
  front_id_photo_screen_viewed: 'front_id_photo_screen_viewed',
  front_id_photo_submitted: 'front_id_photo_submitted',
  front_id_photo_taken: 'front_id_photo_taken',
  joint_account_verifcation_URA_completed: 'joint_account_verifcation_URA_completed',
  joint_account_verifcation_URA_viewed: 'joint_account_verifcation_URA_viewed',
  link_bank_account_bank_selected: 'link_bank_account_bank_selected',
  link_bank_account_failed: 'link_bank_account_failed',
  link_bank_account_primer_completed: 'link_bank_account_primer_completed',
  link_bank_account_primer_viewed: 'link_bank_account_primer_viewed',
  link_bank_account_current_account_selected: 'link_bank_account_current_account_selected',
  link_bank_account_relink_account_selected: 'link_bank_account_relink_account_selected',
  link_bank_account_new_account_selected: 'link_bank_account_new_account_selected',
  link_bank_account_successful: 'link_bank_account_successful',
  link_bank_account_unsupported_account_try_again:
    'link_bank_account_unsupported_account_try_again',
  link_bank_account_unsupported_account_viewed: 'link_bank_account_unsupported_account_viewed',
  link_bank_account_unsupported_institution_try_again:
    'link_bank_account_unsupported_institution_try_again',
  link_bank_account_unsupported_institution_viewed:
    'link_bank_account_unsupported_institution_viewed',
  loan_amount_selected: 'loan_amount_selected',
  loan_amount_selection_screen_viewed: 'loan_amount_selection_screen_viewed',
  loan_application_processing_screen_viewed: 'loan_application_processing_screen_viewed',
  marketing_source_survey_screen_viewed: 'marketing_source_survey_screen_viewed',
  marketing_source_survey_selected: 'marketing_source_survey_selected',
  personal_details_address_screen_completed: 'personal_details_address_screen_completed',
  personal_details_address_screen_viewed: 'personal_details_address_screen_viewed',
  personal_details_completed: 'personal_details_completed',
  personal_details_confirm_page_completed: 'personal_details_confirm_page_completed',
  personal_details_confirm_page_viewed: 'personal_details_confirm_page_viewed',
  personal_details_screen_viewed: 'personal_details_screen_viewed',
  personal_details_ssn_completed: 'personal_details_ssn_completed',
  personal_details_ssn_helper_selected: 'personal_details_ssn_helper_selected',
  personal_details_ssn_screen_viewed: 'personal_details_ssn_screen_viewed',
  proof_of_income_URA_completed: 'proof_of_income_URA_completed',
  proof_of_income_URA_viewed: 'proof_of_income_URA_viewed',
  take_a_selfie_completed: 'take_a_selfie_completed',
  take_a_selfie_screen_viewed: 'take_a_selfie_screen_viewed',
  unfreeze_bureau_frozen_file_URA_completed: 'unfreeze_bureau_frozen_file_URA_completed',
  unfreeze_bureau_frozen_file_URA_viewed: 'unfreeze_bureau_frozen_file_URA_viewed',
  unsupported_institutions_screen_viewed: 'unsupported_institutions_screen_viewed',
} as const

export const BankAccountManagementEvents = {
  account_management_page_viewed: 'account_management_page_viewed',
  account_management_plaid_complete: 'account_management_plaid_complete',
  account_management_plaid_error: 'account_management_plaid_error',
  account_management_plaid_start: 'account_management_plaid_start',
  account_management_primary_account_set_error: 'account_management_primary_account_set_error',
  account_management_primary_account_set_update_loan_autopay_confirm:
    'account_management_primary_account_set_update_loan_autopay_confirm',
  account_management_primary_account_set_update_loan_autopay_deny:
    'account_management_primary_account_set_update_loan_autopay_deny',
  account_management_primary_account_set: 'account_management_primary_account_set',
  add_new_payment_account_completed: 'add_new_payment_account_completed', // has { type: '' }
  add_new_payment_account_failed: 'add_new_payment_account_failed', // has { type: '' }
  add_new_payment_account_submitted: 'add_new_payment_account_submitted', // has { type: '' }
  add_primary_account_page_viewed: 'add_primary_account_page_viewed',

  bank_account_management_account_routing_selected:
    'bank_account_management_account_routing_selected',
  bank_account_management_add_debit_card_duplicate:
    'bank_account_management_add_debit_card_duplicate',
  bank_account_management_add_debit_card_fail: 'bank_account_management_add_debit_card_fail',
  bank_account_management_add_debit_card_fail_credit:
    'bank_account_management_add_debit_card_fail_credit',
  bank_account_management_add_debit_card_success: 'bank_account_management_add_debit_card_success',
  bank_account_management_add_debit_card_selected:
    'bank_account_management_add_debit_card_selected',
  bank_account_management_add_new_account_viewed: 'bank_account_management_add_new_account_viewed',
  bank_account_management_link_with_plaid_selected:
    'bank_account_management_link_with_plaid_selected',

  bank_add_routing_and_account_error_viewed: 'bank_add_routing_and_account_error_viewed',
  bank_add_routing_and_account_page_viewed: 'bank_add_routing_and_account_page_viewed',
  bank_verify_routing_and_account_error_viewed: 'bank_verify_routing_and_account_error_viewed',
  bank_verify_routing_and_account_page_viewed: 'bank_verify_routing_and_account_page_viewed',
  bank_verify_routing_and_account_submit_completed:
    'bank_verify_routing_and_account_submit_completed',
  bank_verify_routing_and_account_submit_failed: 'bank_verify_routing_and_account_submit_failed',
  change_default_payment_method_URA_completed: 'change_default_payment_method_URA_completed',
  change_default_payment_method_URA_viewed: 'change_default_payment_method_URA_viewed',
  collect_bank_account_numbers_viewed: 'collect_bank_account_numbers_viewed',
  link_account_with_new_name_URA_completed: 'link_account_with_new_name_URA_completed',
  link_account_with_new_name_URA_CTA: 'link_account_with_new_name_URA_CTA',
  link_account_with_new_name_URA_error: 'link_account_with_new_name_URA_error',
  link_account_with_new_name_URA_viewed: 'link_account_with_new_name_URA_viewed',
  bank_ownership_contact_us_selected: 'bank_ownership_contact_us_selected',

  bank_ownership_fail_viewed: 'bank_ownership_fail_viewed',
  bank_ownership_name_challenge_viewed: 'bank_ownership_name_challenge_viewed',
  bank_ownership_name_fail_viewed: 'bank_ownership_name_fail_viewed',
  bank_ownership_non_personal_account_viewed: 'bank_ownership_non_personal_account_viewed',
  bank_ownership_address_fail_viewed: 'bank_ownership_address_fail_viewed',
  bank_ownership_name_and_address_fail_viewed: 'bank_ownership_name_and_address_fail_viewed',
  bank_ownership_joint_account_viewed: 'bank_ownership_joint_account_viewed',

  primary_account_aggregator_account_selection_verify_failed:
    'primary_account_aggregator_account_selection_verify_failed',
  primary_account_aggregator_account_selection_verify_succeeded:
    'primary_account_aggregator_account_selection_verify_succeeded',
  relink_account_set_primary_URA_completed: 'relink_account_set_primary_URA_completed',
  relink_account_set_primary_URA_CTA: 'relink_account_set_primary_URA_CTA',
  relink_account_set_primary_URA_error: 'relink_account_set_primary_URA_error',
  relink_account_set_primary_URA_viewed: 'relink_account_set_primary_URA_viewed',
  select_personal_account_URA_completed: 'select_personal_account_URA_completed',
  select_personal_account_URA_CTA: 'select_personal_account_URA_CTA',
  select_personal_account_URA_error: 'select_personal_account_URA_error',
  select_personal_account_URA_viewed: 'select_personal_account_URA_viewed',
  select_primary_account_page_cta: 'select_primary_account_page_cta',
  select_primary_account_page_error_viewed: 'select_primary_account_page_error_viewed',
  select_primary_account_page_select_cta: 'select_primary_account_page_select_cta',
  select_primary_account_page_viewed: 'select_primary_account_page_viewed',
  set_account_as_primary_page_viewed: 'set_account_as_primary_page_viewed',
} as const

export const CardEvents = {
  // Adhoc payment flow
  card_adhoc_payment_amount_selected: 'card_adhoc_payment_amount_selected',
  card_adhoc_payment_failure_modal_viewed: 'card_adhoc_payment_failure_modal_viewed',
  card_adhoc_payment_relink_account_clicked: 'card_adhoc_payment_relink_account_clicked',
  card_adhoc_payment_review_page_viewed: 'card_adhoc_payment_review_page_viewed',
  card_schedule_adhoc_payment_completed: 'card_schedule_adhoc_payment_completed',
  card_adhoc_payment_select_amount_page_cta: 'card_adhoc_payment_select_amount_page_cta',
  card_adhoc_payment_select_amount_page_viewed: 'card_adhoc_payment_select_amount_page_viewed',
  card_adhoc_payment_success_screen_viewed: 'card_adhoc_payment_success_screen_viewed',
  card_payment_method_link_cta: 'card_payment_method_link_cta',
  card_verify_bank_details_accounted_added_modal_confirm_cta:
    'card_verify_bank_details_accounted_added_modal_confirm_cta',
  card_verify_bank_details_page_viewed: 'card_verify_bank_details_page_viewed',
  card_verify_bank_details_payment_account_select:
    'card_verify_bank_details_payment_account_select',
  card_verify_bank_details_submit_cta: 'card_verify_bank_details_submit_cta',
  card_view_payment_method_page_add_bank_cta: 'card_view_payment_method_page_add_bank_cta',
  card_view_payment_method_page_bank_select_cta: 'card_view_payment_method_page_bank_select_cta',
  card_view_payment_method_page_save_payment_cta: 'card_view_payment_method_page_save_payment_cta',
  card_view_payment_method_page_viewed: 'card_view_payment_method_page_viewed',

  // OTHER
  accept_cardholder_agreement_viewed: 'accept_cardholder_agreement_viewed',
  apply_dashboard_card_cta: 'apply_dashboard_card_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4821%3A19590
  apply_dashboard_card_viewed: 'apply_dashboard_card_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4821%3A19585
  apply_for_card_selected: 'apply_for_card_selected',
  card_activated_page_cta: 'card_activated_page_cta',
  card_activated_page_viewed: 'card_activated_page_viewed',
  card_activating_page_viewed: 'card_activating_page_viewed',
  card_add_debit_card_page_on_submit_cta: 'card_add_debit_card_page_on_submit_cta',
  card_add_debit_card_page_viewed: 'card_add_debit_card_page_viewed',
  card_application_processing_page_viewed: 'card_application_processing_page_viewed',
  card_application_submitted: 'card_application_submitted',
  card_approved_page_cta: 'card_approved_page_cta', // https://www.figma.com/file/Yqrjmw6yPfOAIGCDseK0Ug/New-Activation-Flow-Events?node-id=0%3A1
  card_approved_page_viewed: 'card_approved_page_viewed', // https://www.figma.com/file/Yqrjmw6yPfOAIGCDseK0Ug/New-Activation-Flow-Events?node-id=0%3A1
  card_autopay_amount_selected: 'card_autopay_amount_selected',
  card_autopay_overview_page_cta: 'card_autopay_overview_page_cta',
  card_autopay_schedule_page_continue_cta: 'card_autopay_schedule_page_continue_cta',
  card_autopay_schedule_page_opt_out_cta: 'card_autopay_schedule_page_opt_out_cta',
  card_autopay_schedule_page_viewed: 'card_autopay_schedule_page_viewed',
  card_autopay_settings_page_viewed: 'card_autopay_settings_page_viewed',
  card_dashboard_enroll_autopay_tile_cta: 'card_dashboard_enroll_autopay_tile_cta',
  card_dashboard_pay_over_time_cta: 'card_dashboard_pay_over_time_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19624
  card_dashboard_viewed: 'card_dashboard_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19608
  card_deactivated_dashboard_card_viewed: 'card_deactivated_dashboard_card_viewed',
  card_delinquency_explained_page_viewed: 'card_delinquency_explained_page_viewed',
  card_expired_dashboard_card_viewed: 'card_expired_dashboard_card_viewed',
  card_expired_page_viewed: 'card_expired_page_viewed',
  card_finish_virtual_activation_page_cta: 'card_finish_virtual_activation_page_cta',
  card_finish_virtual_activation_page_viewed: 'card_finish_virtual_activation_page_viewed',
  card_how_manual_pay_works_page_cta: 'card_how_manual_pay_works_page_cta',
  card_how_manual_pay_works_page_viewed: 'card_how_manual_pay_works_page_viewed',
  card_how_pay_over_time_works_page_cta: 'card_how_pay_over_time_works_page_cta',
  card_how_pay_over_time_works_page_viewed: 'card_how_pay_over_time_works_page_viewed',
  card_join_autopay_page_viewed: 'card_join_autopay_page_viewed',
  card_key_info_details_clicked: 'card_key_info_details_clicked',
  card_key_info_details_exited: 'card_key_info_details_exited',
  card_manual_pay_confirmation_popup_viewed: 'card_manual_pay_confirmation_popup_viewed',
  card_manual_pay_schedule_page_cta: 'card_manual_pay_schedule_page_cta',
  card_manual_pay_schedule_page_viewed: 'card_manual_pay_schedule_page_viewed',
  card_over_time_page_accept_plan_cta: 'card_over_time_page_accept_plan_cta',
  card_over_time_page_viewed: 'card_over_time_page_viewed',
  card_pay_over_time_confirmation_page_confirm_cta:
    'card_pay_over_time_confirmation_page_confirm_cta',
  card_pay_over_time_confirmation_page_viewed: 'card_pay_over_time_confirmation_page_viewed',
  card_pending_payment_help_clicked: 'card_pending_payment_help_clicked',
  card_pending_transaction_help_clicked: 'card_pending_transaction_help_clicked',
  card_pending_transaction_help_cta_clicked: 'card_pending_transaction_help_cta_clicked',
  card_physical_activation_cvc_ssn_page_cta: 'card_physical_activation_cvc_ssn_page_cta', // https://www.figma.com/file/Yqrjmw6yPfOAIGCDseK0Ug/New-Activation-Flow-Events
  card_physical_activation_cvc_ssn_page_viewed: 'card_physical_activation_cvc_ssn_page_viewed', // https://www.figma.com/file/Yqrjmw6yPfOAIGCDseK0Ug/New-Activation-Flow-Events
  card_physical_card_activated_page_cta: 'card_physical_card_activated_page_cta',
  card_physical_card_activated_page_viewed: 'card_physical_card_activated_page_viewed',
  card_physical_card_failed_activation_page_later_cta:
    'card_physical_card_failed_activation_page_later_cta',
  card_physical_card_failed_activation_page_retry_cta:
    'card_physical_card_failed_activation_page_retry_cta',
  card_physical_card_failed_activation_page_viewed:
    'card_physical_card_failed_activation_page_viewed',
  card_pot_delinquency_policy_acknowledged: 'card_pot_delinquency_policy_acknowledged',
  card_pot_detail_page_cta: 'card_pot_detail_page_cta',
  card_pot_detail_page_installments_filtered: 'card_pot_detail_page_installments_filtered',
  card_pot_detail_page_viewed: 'card_pot_detail_page_viewed',
  card_pot_pay_now_page_call_cta: 'card_pot_pay_now_page_call_cta',
  card_pot_pay_now_page_viewed: 'card_pot_pay_now_page_viewed',
  card_reject_dashboard_card_viewed: 'card_reject_dashboard_card_viewed',
  card_rejected_page_viewed: 'card_rejected_page_viewed',
  card_reschedule_payments_calendar_date_selected:
    'card_reschedule_payments_calendar_date_selected',
  card_reschedule_payments_screen_reschedule_cta_clicked:
    'card_reschedule_payments_screen_reschedule_cta_clicked',
  card_reschedule_payments_screen_viewed: 'card_reschedule_payments_screen_viewed',
  card_selection_page_viewed: 'card_selection_page_viewed',
  card_waiting_list_dashboard_registered: 'card_waiting_list_dashboard_registered', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4821%3A19565
  card_waiting_list_dashboard_support: 'card_waiting_list_dashboard_support',
  card_waiting_list_dashboard_viewed: 'card_waiting_list_dashboard_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4821%3A19564
  enable_auto_pay_submitted: 'enable_auto_pay_submitted',
  enable_manual_pay_submitted: 'enable_manual_pay_submitted',
  enable_pay_over_time_submitted: 'enable_pay_over_time_submitted',
  out_of_state_wait_list_page_viewed: 'out_of_state_wait_list_page_viewed',
  out_of_state_wait_list_registered: 'out_of_state_wait_list_registered',
  reapply_dashboard_card_cta: 'reapply_dashboard_card_cta',
  reapply_dashboard_card_viewed: 'reapply_dashboard_card_viewed',
  self_reported_income_expenses_screen_viewed: 'self_reported_income_expenses_screen_viewed',
  self_reported_income_expenses_submitted: 'self_reported_income_expenses_submitted',
  virtual_card_viewed: 'virtual_card_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4838%3A19724
  user_added_to_cards: 'user_added_to_cards',
  view_transaction_cta: 'view_transaction_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19629
} as const

export const CashAdvanceEvents = {
  advance_intro_viewed: 'advance_intro_viewed',
  advance_membership_viewed: 'advance_membership_viewed',
  advance_membership_completed: 'advance_membership_completed',
  advance_schedule_viewed: 'advance_schedule_viewed',
  advance_schedule_completed: 'advance_schedule_completed',
  cash_cancel_membership_selected: 'cash_cancel_membership_selected',
  cash_cancel_membership_completed: 'cash_cancel_membership_completed',
  cash_cancel_membership_nevermind: 'cash_cancel_membership_nevermind',
  cash_resume_membership_selected: 'cash_resume_membership_selected',
  cash_next_advance_selected: 'cash_next_advance_selected',
} as const

export const FunctionalEvents = {
  deeplink_cleared: 'deeplink_cleared',
  deeplink_read_from_storage: 'deeplink_read_from_storage',
  deeplink_received: 'deeplink_received',
  deeplink_saved_to_storage: 'deeplink_saved_to_storage',
  redirect_to_webapp_begin: 'redirect_to_webapp_begin',
  redirect_to_webapp_complete: 'redirect_to_webapp_complete',
  url_params_found: 'url_params_found',
} as const

export const GeneralErrorEvents = {
  error_boundary_screen_viewed: 'error_boundary_screen_viewed',
  error_tile_viewed: 'error_tile_viewed',
} as const

const LoginEvents = {
  accept_agreements_view_ecc_selected: 'accept_agreements_view_ecc_selected',
  account_recovery_enter_phone_number_viewed: 'account_recovery_enter_phone_number_viewed',
  account_recovery_instructions_contact_us_selected:
    'account_recovery_instructions_contact_us_selected',
  account_recovery_instructions_viewed: 'account_recovery_instructions_viewed',
  account_recovery_resend_selected: 'account_recovery_resend_selected',
  binary_update_notification_shown: 'binary_update_notification_shown',
  card_registration_page_viewed: 'card_registration_page_viewed',
  card_landing_click_log_in: 'card_landing_click_log_in',
  google_oauth_selected: 'google_oauth_selected',
  intro_tutorial_1_screen_viewed: 'intro_tutorial_1_screen_viewed',
  loan_landing_screen_viewed: 'loan_landing_screen_viewed',
  loan_landing_check_eligibility_CTA: 'loan_landing_check_eligibility_CTA',
  loan_landing_click_log_in: 'loan_landing_click_log_in',
  loan_landing_learn_more_borrow: 'loan_landing_learn_more_borrow',
  loan_landing_learn_more_fees: 'loan_landing_learn_more_fees',
  loan_landing_learn_more_POT: 'loan_landing_learn_more_POT',
  loan_landing_learn_more_debt: 'loan_landing_learn_more_debt',
  login_failed: 'login_failed',
  login_screen_viewed: 'login_screen_viewed',
  login_session_start: 'login_session_start',
  login_succeeded: 'login_succeeded',
  login_credentials_submitted: 'login_credentials_submitted',
  logout_succeeded: 'logout_succeeded',
  otp_code_screen_viewed: 'otp_code_screen_viewed',
  otp_resend_verification_selected: 'otp_resend_verification_selected',
  otp_verification_code_submitted: 'otp_verification_code_submitted',
  partner_landing_screen_viewed: 'partner_landing_screen_viewed',
  register_email_exists: 'register_email_exists',
  registration_screen_viewed: 'registration_screen_viewed',
  registration_existing_log_in: 'registration_existing_log_in',
  registration_get_started: 'registration_get_started',
  review_change_state_unsupported: 'review_change_state_unsupported',
  review_change_state_supported: 'review_change_state_supported',
} as const

export const ManageActiveLoanEvents = {
  active_dashboard_viewed: 'active_dashboard_viewed',
  alert_viewed_charged_off: 'alert_viewed_charged_off',
  alert_viewed_in_default: 'alert_viewed_in_default',
  alert_viewed_one_missed_payment: 'alert_viewed_one_missed_payment',
  alert_viewed_payment_late_not_failed: 'alert_viewed_payment_late_not_failed',
  alert_viewed_payment_method_issue: 'alert_viewed_payment_method_issue',
  alert_viewed_suspended: 'alert_viewed_suspended',
  approved_dashboard_viewed: 'approved_dashboard_viewed', // args { isCounterOffer: boolean }
  approved_decline_counter_offer_selected: 'approved_decline_counter_offer_selected',
  charged_off_explanation_selected: 'charged_off_explanation_selected',
  closed_dashboard_viewed: 'closed_dashboard_viewed', // includes arg { reason: '' }
  contact_us_selected: 'contact_us_selected',
  decisioning_dashboard_viewed: 'decisioning_dashboard_viewed', // args { delayed: boolean }
  default_explanation_selected: 'default_explanation_selected',
  error_loan_dashboard_viewed: 'error_loan_dashboard_viewed',
  final_payment_processing_alert_tapped: 'final_payment_processing_alert_tapped',
  installment_upgrade_accepted: 'installment_upgrade_accepted',
  loans_adhoc_aggregator_account_selection_cta_selected:
    'loans_adhoc_aggregator_account_selection_cta_selected',
  loans_adhoc_aggregator_account_selection_screen_viewed:
    'loans_adhoc_aggregator_account_selection_screen_viewed',
  loans_adhoc_aggregator_account_selection_verify_failed:
    'loans_adhoc_aggregator_account_selection_verify_failed',
  loans_adhoc_aggregator_account_selection_verify_succeeded:
    'loans_adhoc_aggregator_account_selection_verify_succeeded',
  loans_adhoc_amount_entry_cta: 'loans_adhoc_amount_entry_cta',
  loans_adhoc_amount_entry_error_viewed: 'loans_adhoc_amount_entry_error_viewed',
  loans_adhoc_amount_entry_screen_viewed: 'loans_adhoc_amount_entry_screen_viewed',
  loans_adhoc_dashboard_make_a_payment_cta: 'loans_adhoc_dashboard_make_a_payment_cta',
  loans_adhoc_failure_modal_cta_cancel: 'loans_adhoc_failure_modal_cta_cancel',
  loans_adhoc_failure_modal_cta_try_again: 'loans_adhoc_failure_modal_cta_try_again',
  loans_adhoc_failure_modal_viewed: 'loans_adhoc_failure_modal_viewed',
  loans_adhoc_payment_error_screen_viewed: 'loans_adhoc_payment_error_screen_viewed',
  loans_adhoc_payment_method_link_cta: 'loans_adhoc_payment_method_link_cta',
  loans_adhoc_payment_methods_change_payment_method_completed:
    'loans_adhoc_payment_methods_change_payment_method_completed',
  loans_adhoc_payment_methods_change_payment_method:
    'loans_adhoc_payment_methods_change_payment_method',
  loans_adhoc_payment_methods_error_viewed: 'loans_adhoc_payment_methods_error_viewed',
  loans_adhoc_payment_methods_screen_viewed: 'loans_adhoc_payment_methods_screen_viewed',
  loans_adhoc_payment_review_cta: 'loans_adhoc_payment_review_cta',
  loans_adhoc_payment_review_edit_amount_selected:
    'loans_adhoc_payment_review_edit_amount_selected',
  loans_adhoc_payment_review_relink_account: 'loans_adhoc_payment_review_relink_account',
  loans_adhoc_payment_review_screen_viewed: 'loans_adhoc_payment_review_screen_viewed',
  loans_adhoc_payment_review_view_payment_methods:
    'loans_adhoc_payment_review_view_payment_methods',
  loans_adhoc_success_screen_viewed: 'loans_adhoc_success_screen_viewed',
  pay_remaining_now_selected: 'pay_remaining_now_selected',
  reapply_for_loan_selected: 'reapply_for_loan_selected',
  rejected_explanation_selected: 'rejected_explanation_selected',
  relink_bank_account_selected: 'relink_bank_account_selected',
  reschedule_alert_continue_selected: 'reschedule_alert_continue_selected',
  reschedule_alert_nevermind_selected: 'reschedule_alert_nevermind_selected',
  reschedule_alert_viewed: 'reschedule_alert_viewed',
  reschedule_payments_calendar_completed: 'reschedule_payments_calendar_completed',
  reschedule_payments_calendar_confirm_failed: 'reschedule_payments_calendar_confirm_failed',
  reschedule_payments_calendar_screen_viewed: 'reschedule_payments_calendar_screen_viewed',
  reschedule_payments_calendar_selected: 'reschedule_payments_calendar_selected',
  reschedule_payments_payment_selected: 'reschedule_payments_payment_selected',
  reschedule_payments_screen_viewed: 'reschedule_payments_screen_viewed',
  reschedule_payments_selected: 'reschedule_payments_selected',
  state_db_explanation_selected: 'state_db_explanation_selected',
  update_payment_method_selected: 'update_payment_method_selected',
  view_history_selected: 'view_history_selected',
} as const

export const isPrioritizedPlaidSDKEvent = (
  event: string,
): event is (typeof PlaidSDKEvents)[keyof typeof PlaidSDKEvents] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, no-type-assertion/no-type-assertion, @typescript-eslint/no-explicit-any
  return Object.values(PlaidSDKEvents).includes(event as any)
}

// most of these events come from the plaid SDK event emitter and are not sent by our code
export const PlaidSDKEvents = {
  plaid_error: 'plaid_error',
  plaid_exit: 'plaid_exit',
  plaid_refactor_activated: 'plaid_refactor_activated',
  plaid_select_institution: 'plaid_select_institution',
  plaid_success: 'plaid_success',
  // Plaid Layer
  plaid_layer_error: 'plaid_layer_error',
  plaid_layer_exit: 'plaid_layer_exit',
  plaid_layer_ineligible: 'plaid_layer_ineligible',
  plaid_layer_layer_prompt_complete: 'plaid_layer_layer_prompt_complete',
  plaid_layer_opt_back_in: 'plaid_layer_opt_back_in',
  plaid_layer_opt_out: 'plaid_layer_opt_out',
  plaid_layer_process_complete: 'plaid_layer_process_complete',
  plaid_layer_skip_submit_phone: 'plaid_layer_skip_submit_phone',
  plaid_layer_start: 'plaid_layer_start',
  plaid_layer_submit_credentials: 'plaid_layer_submit_credentials',
  plaid_layer_submit_MFA: 'plaid_layer_submit_MFA',
  plaid_layer_submit_phone: 'plaid_layer_submit_phone',
  plaid_layer_view_layer_prompt: 'plaid_layer_view_layer_prompt',
} as const

export const WorkflowEvents = {
  application_activation_workflow_entered: 'application_activation_workflow_entered',
  application_activation_workflow_error_contact_us_pressed:
    'application_activation_workflow_error_contact_us_pressed',
  application_activation_workflow_error: 'application_activation_workflow_error',
  application_activation_workflow_exited: 'application_activation_workflow_exited',
  application_activation_workflow_post_error_viewed:
    'application_activation_workflow_post_error_viewed',
  offer_application_workflow_entered: 'offer_application_workflow_entered',
  offer_application_workflow_error_contact_us_pressed:
    'offer_application_workflow_error_contact_us_pressed',
  offer_application_workflow_error: 'offer_application_workflow_error',
  offer_application_workflow_exited: 'offer_application_workflow_exited',
  product_hub_workflow_error_contact_us_pressed: 'product_hub_workflow_error_contact_us_pressed',
  product_hub_workflow_error: 'product_hub_workflow_error',
  signup_workflow_entered: 'signup_workflow_entered',
  signup_workflow_error: 'signup_workflow_error',
  signup_workflow_exited: 'signup_workflow_exited',
  workflow_navigation_failed: 'workflow_navigation_failed',
  workflow_navigation: 'workflow_navigation',

  // these are temporary debug events
  workflow_initial_route_error: 'workflow_initial_route_error',
  workflow_pre_reqs_complete: 'workflow_pre_reqs_complete',
  workflow_setup_route_history_failed: 'workflow_setup_route_history_failed',
  workflow_unknown_destination: 'workflow_unknown_destination',
} as const

const AppEvents = {
  Category: {
    Activation: 'Activation',
    Admin: 'Admin',
    Application: 'Application',
    BankAccountManagement: 'BankAccountManagement',
    Card: 'Card',
    CashAdvance: 'CashAdvance',
    Functional: 'Functional',
    GeneralError: 'GeneralError',
    Login: 'Login',
    ManageActiveLoan: 'ManageActiveLoan',
    Onboarding: 'Onboarding',
    PlaidSDK: 'PlaidSDK',
    Workflows: 'Workflows',
  },
  Name: {
    // As possible these events should be added to new or existing categories
    ach_agreement_selected: 'ach_agreement_selected',
    application_steps_screen_viewed: 'application_steps_screen_viewed',
    arbitration_agreement_selected: 'arbitration_agreement_selected',
    card_statement_selected: 'card_statement_selected',
    cardholder_agreement_selected: 'cardholder_agreement_selected',
    cardholder_beta_agreement_selected: 'cardholder_beta_agreement_selected',
    cardholder_repayment_authorization_selected: 'cardholder_repayment_authorization_selected',
    credit_service_agreement_selected: 'credit_service_agreement_selected',
    credit_service_disclosure_selected: 'credit_service_disclosure_selected',
    installment_loan_agreement_selected: 'installment_loan_agreement_selected',
    lefthand_drawer_card_statements_and_documents_screen_viewed:
      'lefthand_drawer_card_statements_and_documents_screen_viewed',
    lefthand_drawer_card_statements_and_documents_selected:
      'lefthand_drawer_card_statements_and_documents_selected',
    lefthand_drawer_contact_us_selected: 'lefthand_drawer_contact_us_selected',
    lefthand_drawer_legal_selected: 'lefthand_drawer_legal_selected',
    lefthand_drawer_loan_history_screen_viewed: 'lefthand_drawer_loan_history_screen_viewed',
    lefthand_drawer_loan_history_selected: 'lefthand_drawer_loan_history_selected',
    pay_off_confirmation_doc_selected: 'pay_off_confirmation_doc_selected',
    prequal_bank_link_viewed: 'prequal_bank_link_viewed',
    provide_proof_of_residency_completed: 'provide_proof_of_residency_completed',
    single_payment_loan_agreement_selected: 'single_payment_loan_agreement_selected',
    ssn_photo_confirmation_screen_viewed: 'ssn_photo_confirmation_screen_viewed',
    ssn_photo_retaken: 'ssn_photo_retaken',
    ssn_photo_screen_viewed: 'ssn_photo_screen_viewed',
    ssn_photo_submitted: 'ssn_photo_submitted',
    ssn_photo_taken: 'ssn_photo_taken',
    state_disclosure_accepted: 'state_disclosure_accepted',
    state_disclosure_exit: 'state_disclosure_exit',
    state_disclosure_screen_viewed: 'state_disclosure_screen_viewed',
    state_selector_viewed: 'state_selector_viewed',
    state_selector_submitted: 'state_selector_submitted',
    collect_state_id_viewed: 'collect_state_id_viewed',
    collect_state_id_submitted: 'collect_state_id_submitted',
    verify_bank_details_accounted_added_modal_confirm_cta:
      'verify_bank_details_accounted_added_modal_confirm_cta',
    verify_bank_details_page_viewed: 'verify_bank_details_page_viewed',
    verify_bank_details_payment_account_select: 'verify_bank_details_payment_account_select',
    verify_bank_details_submit_cta: 'verify_bank_details_submit_cta',

    // WA installment loan
    replacement_loan_ach_agreement_selected: 'replacement_loan_ach_agreement_selected',
    replacement_loan_agreement_selected: 'replacement_loan_agreement_selected',

    ...ActivationEvents,

    ...AdminEvents,

    ...ApplicationEvents,

    ...BankAccountManagementEvents,

    ...CardEvents,

    ...CashAdvanceEvents,

    ...FunctionalEvents,

    ...GeneralErrorEvents,

    ...LoginEvents,

    ...ManageActiveLoanEvents,

    ...PlaidSDKEvents,

    ...WorkflowEvents,
  },
} as const //const assertion to get the literal types from the object

export {AppEvents}

type ValueOf<T> = T[keyof T]
export type AppEventName = ValueOf<typeof AppEvents.Name>
export type AppEventCategory = ValueOf<typeof AppEvents.Category>

/**
 * Opt in events to be sent to Braze. By default events are not sent to Braze.
 */
export const brazeEvents: Partial<Record<AppEventName, boolean>> = {
  [ActivationEvents.accept_agreements_accept_loan_agreement_selected]: true,
  [ActivationEvents.accept_agreements_completed]: true,
  [ActivationEvents.add_debit_card_confirmed]: true,
  [ActivationEvents.completed_application_submitted]: true,
  [ActivationEvents.enter_email_verification_code_submitted]: true,
  [ActivationEvents.enter_phone_number_completed]: true,
  [ActivationEvents.enter_phone_number_screen_viewed]: true,
  [ActivationEvents.enter_verification_code_screen_viewed]: true,
  [ActivationEvents.enter_verification_code_submitted]: true,
  [ActivationEvents.final_loan_acceptance_completed]: true,
  [ActivationEvents.final_loan_acceptance_failed]: true,
  [ActivationEvents.final_loan_acceptance_screen_viewed]: true,
  [ActivationEvents.installment_upgrade_screen_viewed]: true,
  [ActivationEvents.purpose_for_loan_screen_viewed]: true,
  [ApplicationEvents.completed_application_submission_screen_viewed]: true,
  [ApplicationEvents.front_id_photo_screen_viewed]: true,
  [ApplicationEvents.link_bank_account_bank_selected]: true,
  [ApplicationEvents.link_bank_account_successful]: true,
  [ApplicationEvents.link_bank_account_unsupported_account_viewed]: true,
  [ApplicationEvents.link_bank_account_unsupported_institution_viewed]: true,
  [ApplicationEvents.loan_amount_selected]: true,
  [ApplicationEvents.loan_amount_selection_screen_viewed]: true,
  [ApplicationEvents.personal_details_ssn_completed]: true,
  [ApplicationEvents.personal_details_ssn_screen_viewed]: true,
  [ApplicationEvents.take_a_selfie_screen_viewed]: true,
  [BankAccountManagementEvents.bank_ownership_address_fail_viewed]: true,
  [BankAccountManagementEvents.bank_ownership_contact_us_selected]: true,
  [BankAccountManagementEvents.bank_ownership_fail_viewed]: true,
  [BankAccountManagementEvents.bank_ownership_joint_account_viewed]: true,
  [BankAccountManagementEvents.bank_ownership_name_and_address_fail_viewed]: true,
  [BankAccountManagementEvents.bank_ownership_name_challenge_viewed]: true,
  [BankAccountManagementEvents.bank_ownership_name_fail_viewed]: true,
  [BankAccountManagementEvents.bank_ownership_non_personal_account_viewed]: true,
  [CardEvents.apply_for_card_selected]: true,
  [CardEvents.card_activated_page_viewed]: true,
  [CardEvents.card_application_submitted]: true,
  [CardEvents.card_approved_page_viewed]: true,
  [CardEvents.card_dashboard_viewed]: true,
  [CardEvents.card_physical_card_activated_page_viewed]: true,
  [CardEvents.card_selection_page_viewed]: true,
  [CardEvents.card_waiting_list_dashboard_registered]: true,
  [CardEvents.card_waiting_list_dashboard_viewed]: true,
  [CardEvents.enable_auto_pay_submitted]: true,
  [CardEvents.enable_pay_over_time_submitted]: true,
  [CardEvents.out_of_state_wait_list_page_viewed]: true,
  [CardEvents.out_of_state_wait_list_registered]: true,
  [LoginEvents.account_recovery_enter_phone_number_viewed]: true,
  [LoginEvents.intro_tutorial_1_screen_viewed]: true,
  [LoginEvents.login_failed]: true,
  [LoginEvents.login_screen_viewed]: true,
  [LoginEvents.login_succeeded]: true,
  [ManageActiveLoanEvents.installment_upgrade_accepted]: true,
  [ManageActiveLoanEvents.reapply_for_loan_selected]: true,
  [ManageActiveLoanEvents.reschedule_payments_calendar_completed]: true,
  [ManageActiveLoanEvents.reschedule_payments_calendar_screen_viewed]: true,
  [ManageActiveLoanEvents.reschedule_payments_calendar_selected]: true,
  [ManageActiveLoanEvents.reschedule_payments_screen_viewed]: true,
  [ManageActiveLoanEvents.reschedule_payments_selected]: true,
}

export type AppEvent = {
  name: AppEventName
  category: AppEventCategory
  eventArgs?: {[key: string]: unknown}
}
