import React, {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  AmountFinancedTilaLineItem,
  AprTilaLineItem,
  FinanceChargeTilaLineItem,
  PaymentScheduleTilaLineItem,
  TotalOfPaymentsTilaLineItem,
} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/TilaLineItem'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {FinanceChargeBreakdown} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/FinanceChargeBreakdown'
import {LoanTilaContentData} from 'src/products/loans/LoanApprovedActivation/components/LoanTilaContent/LoanTilaContent.types'

const LoanTilaContent: FC<LoanTilaContentData> = (props) => {
  const {apr, totalCost, totalOwed, amountApproved, cabFee, interestFee, stateCode} = props
  const {t} = useTranslation('LoanApproved')

  return (
    <Box>
      <Box gap={'little'} marginBottom={'small'}>
        <PFText variant={'h3'}>{t('FederalTruthInLendingAct')}</PFText>

        <PFText variant={'p_sm'}>
          <Trans i18nKey={'ShortTermLoanDescription'} t={t}>
            This short term loan is intended to be paid off within a couple months. The
            <PFText variant={'p_sm_semibold'}>APR will look higher</PFText>
            given it is calculating based on a full year calculation.
          </Trans>
        </PFText>
      </Box>
      <Box gap={'small'} marginBottom={'small'}>
        <AprTilaLineItem apr={apr ?? ''} />
        <Box gap={'tiny'}>
          <FinanceChargeTilaLineItem totalCost={totalCost ?? ''} />
          <FinanceChargeBreakdown
            cabFee={cabFee ?? ''}
            interestFee={interestFee ?? ''}
            stateCode={stateCode ?? undefined}
          />
        </Box>
        <AmountFinancedTilaLineItem amountApproved={amountApproved ?? ''} />
        <TotalOfPaymentsTilaLineItem totalOwed={totalOwed ?? ''} />
        <PaymentScheduleTilaLineItem />
      </Box>
    </Box>
  )
}

export {LoanTilaContent}
