import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'
import {ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'

export type LoanRejectedTileProps = {
  onLoanRejectedLearnMore: ButtonAction
  userEmailAddress: string
  reapplyOnDate: Date
  testID?: string
  isAdvance?: boolean
}
/**
 * A tile to inform the user that they've paid off their loan.
 * Includes an action button to apply for a new loan.
 * @example <LoanRejectedTile userEmailAddress='foo@mail.com' reapplyOnDate={new Date()} onLoanRejectedLearnMore={() => {}} />
 */
const LoanRejectedTile: React.FC<LoanRejectedTileProps> = ({
  onLoanRejectedLearnMore: handleOnLoanRejectedLearnMore,
  userEmailAddress,
  reapplyOnDate,
  testID,
  isAdvance,
}) => {
  const {t} = useTranslation('DashboardLoanRejectedTile')
  return (
    <BasicTile padding="medium" testID={testID}>
      <Box marginTop="little">
        <PFText variant="h3" textAlign="left">
          {isAdvance ? t('YouAreIneligibleForMembershipAtThisTime') : t('WeCouldNotApprove')}
        </PFText>
      </Box>
      <Box marginTop="small">
        <PFText variant="p">
          {isAdvance ? t('YouWontBeCharged') : t('WeSentAMessage', {email: userEmailAddress})}
        </PFText>
      </Box>
      <Box marginTop="medium">
        <PFText variant="p" textAlign="left">
          {t('ApplyAgainOnDate', {
            monthAndDay: `${reapplyOnDate.getMonth() + 1}/${reapplyOnDate.getDate() + 1}`,
          })}
        </PFText>
      </Box>
      {!isAdvance ? (
        <Box width="100%" marginTop="medium" align="start">
          <ButtonLink
            onPress={handleOnLoanRejectedLearnMore}
            textVariantOverride="p"
            testID="LoanRejectedTile-LearnMoreLink"
          >
            {t('LearnMoreLink')}
          </ButtonLink>
        </Box>
      ) : null}
    </BasicTile>
  )
}

export default LoanRejectedTile
