import React from 'react'

import {CollectIDStateNVTemplate} from 'src/products/general/CollectIDStateNV/CollectIDStateNVTemplate'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {UserIdInfoAddDocument} from 'src/products/general/CollectIDStateNV/queries/UserIdInfoAdd.gqls'
import {CollectIDStateNVFormType} from 'src/products/general/CollectIDStateNV/CollectIDStateNV.types'
import Log from 'src/lib/loggingUtil'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

type Props = WorkflowPreReqFulfillScreenProps

const CollectIDStateNVWorkflowContainer: React.FC<Props> = (props): JSX.Element => {
  const {onPreReqFulfilled} = props
  const [collectIdInfo, {loading: isLoadingCollectIDState, error}] = useCassandraMutation(
    UserIdInfoAddDocument,
    {
      onError: (error) => {
        Log.error(`CollectIDStateNV Screen - UserIdInfoAdd mutation: ${error.message}`)
      },
    },
  )

  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.Application,
    eventName: AppEvents.Name.collect_state_id_viewed,
  })

  const handleOnSubmit = async ({
    idNumber: govtIssuedIdNumber,
    idType,
    stateWhereIssued: issuingState,
  }: CollectIDStateNVFormType): Promise<void> => {
    await collectIdInfo({variables: {govtIssuedIdNumber, type: idType, issuingState}})
    TrackAppEvent(AppEvents.Name.collect_state_id_submitted, AppEvents.Category.Application, {
      govtIssuedIdNumber,
      issuingState,
    })
    void onPreReqFulfilled()
  }

  return (
    <BaseTemplate testID="CollectIDStateNVWorkflowContainer" error={error}>
      <CollectIDStateNVTemplate onSubmit={handleOnSubmit} isLoading={isLoadingCollectIDState} />
    </BaseTemplate>
  )
}

export {CollectIDStateNVWorkflowContainer}
