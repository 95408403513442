import React, {ReactNode, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {LoanPaidOffTile} from 'src/products/loans/Dashboard/LoanPaidOffTile/LoanPaidOffTile'
import {LoanProgressTile} from 'src/products/loans/Dashboard/LoanProgressTile/LoanProgressTile'
import LoanRejectedTile from 'src/products/loans/Dashboard/LoanRejectedTile/LoanRejectedTile'
import {LoanExpiredTile} from 'src/products/loans/Dashboard/LoanExpiredTile/LoanExpiredTile'
import LoanUnmetStateRequirementsTile from 'src/products/loans/Dashboard/LoanUnmetStateRequirementsTile/LoanUnmetStateRequirementsTile'
import {LoanCancelledTile} from 'src/products/loans/Dashboard/LoanCancelledTile/LoanCancelledTile'
import {LoanEligibleToApplyTile} from 'src/products/loans/Dashboard/LoanEligibleToApplyTile/LoanEligibleToApplyTile'
import {LoanChargedOffSettledTile} from 'src/products/loans/Dashboard/LoanChargedOffSettledTile/LoanChargedOffSettledTile'
import {LoanHistoryTile} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile'
import TileListView, {
  type ReactNodeWithStyle,
} from 'src/products/loans/components/molecules/TileListView/TileListView'
import {
  LoanClosedReasonCode,
  LoanOfferAvailabilityStatus,
  LoanTypeVariant,
  SubscriptionStatus,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {isLoanClosedReasonCodePaidOff} from 'src/lib/loans/loansUtilsV2'
import {LoanPaidOff, TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CashAdvanceEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {usePrevious} from 'src/lib/utils/hooks'
import {
  checkIfEligibleToApplyAgain,
  shouldShowLoanPaidOffTileForPaidOffLoan,
} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.utils'
import {DashboardLoanClosedProps} from 'src/products/loans/Dashboard/DashboardLoanClosed/DashboardLoanClosed.types'
import {usePromise} from 'src/lib/usePromise/usePromise'

import {InfoTile} from 'src/designSystem/components/molecules/InfoTile/InfoTile'
import {isAdvanceVariant} from 'src/lib/advance/CashAdvanceUtils'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {LoanBrazeTiles} from 'src/products/loans/Dashboard/LoanBrazeTiles/LoanBrazeTiles'

/**
 * Dashboard displayed when a loan is closed and paid off.
 */

const DashboardLoanClosed: React.FC<DashboardLoanClosedProps> = (props) => {
  const {
    reason,
    reapplyOn,
    prequalAmount,
    payments,
    userEmailAddress,
    hasPreviousLoan,
    disbursement,
    onApplyForNewLoan,
    onShowDefaultExplanation: handleOnShowDefaultExplanation,
    onShowChargedOffExplanation: handleOnShowChargedOffExplanation,
    onContactUs: handleOnContactUs,
    onLoanRejectedLearnMore: handleOnLoanRejectedLearnMore,
    onViewStateDatabasePolicy: handleOnViewStateDatabasePolicy,
    onViewLoanHistory: handleOnViewLoanHistory,
    loanTypeVariant,
    subscriptions,
    loanOffers,
  } = props

  const {t} = useTranslation('DashboardLoanActive')

  const availableOffer = loanOffers.find(
    (offer) => offer.loanOfferAvailabilityStatus === LoanOfferAvailabilityStatus.OfferAvailable,
  )

  const hasStandardLoanOffer: boolean =
    availableOffer?.offers[0].loanTypeVariant !== LoanTypeVariant.NonRecourseInstallment

  const reapplyOnDateTime = new Date(reapplyOn)
  const previousCloseReason = usePrevious(reason)
  const isAdvance = isAdvanceVariant(loanTypeVariant)

  useEffect(() => {
    if (previousCloseReason !== reason) {
      if (isLoanClosedReasonCodePaidOff(reason)) {
        // track when a loan is paid off
        LoanPaidOff()
      }
      // the first time a close reason is viewed we track an analytics event for that particular close reason
      TrackAppEvent(
        ManageActiveLoanEvents.closed_dashboard_viewed,
        AppEvents.Category.ManageActiveLoan,
        {
          reason,
        },
      )
    }
  }, [previousCloseReason, reason])

  const {isAfterReapplyOnDate, shouldShowEligibleToApplyTile} = checkIfEligibleToApplyAgain({
    reason,
    reapplyOn,
    prequalAmount,
  })

  const tiles: (ReactNode | ReactNodeWithStyle)[] = []

  const [handleOnApply, {isLoading: isApplyLoading}] = usePromise(
    async (sourceForAnalytics: string | undefined): Promise<void> => {
      await onApplyForNewLoan({sourceForAnalytics})
    },
  )

  const hasActiveSubscription = subscriptions?.current !== null

  if (isAdvance && subscriptions?.current?.status === SubscriptionStatus.Cancelled) {
    tiles.push(
      <InfoTile
        style={{marginHorizontal: 0}}
        headerProps={{
          title: t('CancelledMembershipTitle'),
          body: [t('CancelledMembershipBody')],
        }}
        primary={{
          text: t('CancelledMembershipButton'),
          onPress: async (): Promise<void> => {
            TrackAppEvent(
              CashAdvanceEvents.cash_resume_membership_selected,
              AppEvents.Category.CashAdvance,
            )
            await handleOnApply('DashboardLoanClosed-Membership-Cancelled-Info-Tile')
          },
        }}
      />,
    )
  }

  const isContentCardsEnabled = useIsFeatureFlagEnabled('braze-content-cards')

  const addContentCardTileIfAvailable = (): void => {
    if (isContentCardsEnabled) {
      tiles.push({
        element: <LoanBrazeTiles location={3} includeMarginTop />,
        style: {
          marginTop: 0,
        },
      })
    }
  }

  if (shouldShowEligibleToApplyTile) {
    tiles.push(
      <>
        <LoanEligibleToApplyTile
          testID="DashboardLoanClosed-LoanEligibleToApplyTile"
          prequalifiedAmount={prequalAmount ? parseFloat(prequalAmount) : undefined}
          onApplyForNewLoan={async (): Promise<void> => {
            if (isAdvance) {
              TrackAppEvent(
                CashAdvanceEvents.cash_next_advance_selected,
                AppEvents.Category.CashAdvance,
              )
            }
            await handleOnApply('DashboardLoanClosed-LoanEligibleToApplyTile')
          }}
          isLoading={isApplyLoading}
          isAdvance={isAdvance}
          hasActiveSubscription={hasActiveSubscription}
        />
      </>,
    )
  }

  switch (reason) {
    case LoanClosedReasonCode.Paidoff:
    case LoanClosedReasonCode.ChargedoffPaidoff:
      // we won't show paid off loan users the LoanPaidOffTile if they had a prequal
      // since we want them to see the prequal offer in LoanEligibleToApplyTile instead
      if (
        shouldShowLoanPaidOffTileForPaidOffLoan({
          isAfterReapplyOnDate,
          prequalAmount,
        }) &&
        (!isAdvance || subscriptions?.current?.status !== SubscriptionStatus.Cancelled)
      ) {
        tiles.push(
          <>
            <LoanPaidOffTile
              hasLoanOffer={hasStandardLoanOffer}
              isAdvance={isAdvance}
              isBusy={isApplyLoading}
              onApplyForNewLoan={async (): Promise<void> => {
                if (isAdvance) {
                  TrackAppEvent(
                    CashAdvanceEvents.cash_next_advance_selected,
                    AppEvents.Category.CashAdvance,
                  )
                }
                await handleOnApply('DashboardLoanClosed-LoanPaidOffTile')
              }}
              reapplyOnDate={reapplyOnDateTime}
              testID="DashboardLoanClosed-LoanPaidOffTile"
            />
          </>,
        )
      }

      addContentCardTileIfAvailable()

      tiles.push(
        <>
          <LoanProgressTile
            testID="DashboardLoanClosed-LoanProgressTile"
            payments={payments}
            fundingFailed={
              false /* not used when loan is closed since we only show this if its fully paid off */
            }
            amountProcessing="0.00" /* a closed loan will never have any amount processing / in progress */
            noAccountLinked={false /* same as fundingFailed */}
            onShowDefaultExplanation={handleOnShowDefaultExplanation}
            onShowChargedOffExplanation={handleOnShowChargedOffExplanation}
            onReschedule={
              /* when a loan is closed it cant be rescheduled so this isn't necessary */

              (): void => {}
            }
            onContactUs={handleOnContactUs}
            onRelinkBankAccount={
              /* when a loan is closed there is no UX to relink a bank account so this isn't necessary */

              (): void => {}
            }
          />
        </>,
      )
      // this should always exist on a paid off loan but per the type it may be undefined so we need a safety check to please TypeScript
      if (disbursement) {
        tiles.push(
          <>
            <LoanHistoryTile
              loanPayments={payments}
              hasPreviousLoan={hasPreviousLoan}
              onViewLoanHistory={handleOnViewLoanHistory}
              loanDisbursement={disbursement}
              testID="DashboardLoanClosed-LoanHistoryTile"
              loanTypeVariant={loanTypeVariant}
              subscriptions={subscriptions}
            />
          </>,
        )
      }
      break
    case LoanClosedReasonCode.Rejected:
      if (isAfterReapplyOnDate) {
        // if it's after the reapplyOn date they should see the LoanEligibleToApplyTile
        // to let them apply for a new loan
        addContentCardTileIfAvailable()
        break
      }
      tiles.push(
        <>
          <LoanRejectedTile
            testID="DashboardLoanClosed-LoanRejectedTile"
            onLoanRejectedLearnMore={handleOnLoanRejectedLearnMore}
            userEmailAddress={userEmailAddress}
            reapplyOnDate={reapplyOnDateTime}
            isAdvance={isAdvance}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.LoanExpired:
    case LoanClosedReasonCode.DecisioningExpired:
      tiles.push(
        <>
          <LoanExpiredTile
            wasApproved={reason === LoanClosedReasonCode.LoanExpired}
            testID="DashboardLoanClosed-LoanExpiredTile"
            onApplyForNewLoan={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanExpiredTile')
            }}
            isBusy={isApplyLoading}
            isAdvance={isAdvance}
          />
        </>,
      )
      addContentCardTileIfAvailable()
      break
    case LoanClosedReasonCode.UnmetStateRequirement:
      if (isAfterReapplyOnDate) {
        break
      }
      tiles.push(
        <>
          <LoanUnmetStateRequirementsTile
            testID="DashboardLoanClosed-LoanUnmetStateRequirementsTile"
            onApplyAgain={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanUnmetStateRequirementsTile')
            }}
            onViewStateDatabasePolicy={handleOnViewStateDatabasePolicy}
            isBusy={isApplyLoading}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.Cancelled:
      tiles.push(
        <>
          <LoanCancelledTile
            testID="DashboardLoanClosed-LoanCancelledTile"
            onApplyForNewLoan={async (): Promise<void> => {
              await handleOnApply('DashboardLoanClosed-LoanCancelledTile')
            }}
            isBusy={isApplyLoading}
          />
        </>,
      )
      break
    case LoanClosedReasonCode.ChargedoffSettled:
      tiles.push(
        <LoanChargedOffSettledTile
          hasLoanOffer={hasStandardLoanOffer}
          isBusy={isApplyLoading}
          onApplyForNewLoan={async (): Promise<void> => {
            await handleOnApply('DashboardLoanClosed-LoanChargedOffSettledTile')
          }}
          reapplyOnDate={reapplyOnDateTime}
          testID="DashboardLoanClosed-LoanChargedOffSettledTile"
        />,
      )
      addContentCardTileIfAvailable()
      break
    case LoanClosedReasonCode.Other:
      throw new Error('"Other" close reason not implemented')
  }
  return <TileListView testID="DashboardLoanClosed" list={tiles} />
}

export {DashboardLoanClosed}
