import React, {FC} from 'react'
import {Pressable} from 'react-native'

import {NamedColors} from 'src/designSystem/colors'
import {BankAccountInfo} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {
  accountHasError,
  hasValidOwnershipStatus,
} from 'src/products/MCU/AccountManagementV2/AccountManagement.utils'
import {BankAccountTileContent} from 'src/products/MCU/AccountManagementV2/BankAccountTile/BankAccountTileContent'
import Box from 'src/designSystem/components/atoms/Box/Box'

type BankAccountTileViewProps = {
  account: BankAccountInfo
  onEditPress: () => void
  onAlertPress: () => void
  onOwnershipStatusAlertPress: () => void
  showEditIcon: boolean
}

export const BankAccountTileView: FC<BankAccountTileViewProps> = ({
  account,
  onEditPress: handleOnEditPress,
  onAlertPress: handleOnAlertPress,
  onOwnershipStatusAlertPress: handleOnOwnershipStatusAlertPress,
  showEditIcon,
}) => {
  const accountNumber = '\u2022\u2022\u2022\u2022' + account.mask

  const hasAccountError = accountHasError(account)

  const getIcon = (): 'warning' | 'edit' | undefined => {
    if (hasAccountError) {
      return 'warning'
    } else if (showEditIcon) {
      return 'edit'
    }
    return undefined
  }

  const handleOnPressAction = (): undefined | (() => void) => {
    if (!hasValidOwnershipStatus(account)) {
      return handleOnOwnershipStatusAlertPress
    } else if (!account.isRoutingAndAccountNumbersAvailable || account.isRelinkRequired) {
      return handleOnAlertPress
    } else if (showEditIcon) {
      return handleOnEditPress
    } else {
      return undefined
    }
  }

  return (
    <Pressable onPress={handleOnPressAction()} testID={'Bank-Account-Tile-Pressable'}>
      <Box
        align={'center'}
        direction={'row'}
        padding={'small'}
        border={{
          color: hasAccountError ? NamedColors.PRODUCT_GOLD : NamedColors.ASH,
          width: 2,
        }}
        radius={4}
        testID="Bank-Account-Tile-View"
      >
        <BankAccountTileContent
          accountName={account.name}
          accountNumber={accountNumber}
          icon={getIcon()}
          wasAccountJustAdded={account.isAccountJustAdded}
          isDefaultLoanPaymentMethod={account.isDefaultLoanPaymentMethod}
          isPrimaryAccount={account.primary}
        />
      </Box>
    </Pressable>
  )
}
