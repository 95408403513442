import React, {useCallback} from 'react'

import {ButtonAction} from 'src/designSystem/types'
import {
  useAcceptSubscriptionAndAdvanceQuery,
  useAcceptSubscriptionMutation,
} from 'src/products/loans/LoanApprovedActivation/advanceUtils/advanceGqlUtils'
import {MembershipApprovedTile} from 'src/products/advance/Dashboard/MembershipApprovedTile/MembershipApprovedTile'

export type MembershipApprovedTileContainerProps = {
  amount: number
  onAcceptOffer: ButtonAction
  testID?: string
}

const MembershipApprovedTileContainer: React.FC<MembershipApprovedTileContainerProps> = (props) => {
  const {onAcceptOffer, amount, testID} = props
  const query = useAcceptSubscriptionAndAdvanceQuery()

  const mutate = useAcceptSubscriptionMutation(query?.loanId)

  const handleAcceptAutoPay = useCallback(async () => {
    const isSuccess = await mutate()
    if (!isSuccess) return

    await onAcceptOffer?.()
  }, [mutate, onAcceptOffer])

  return (
    <MembershipApprovedTile
      amount={amount}
      testID={testID}
      loanId={query?.loanId ?? ''}
      onAcceptAutoPay={handleAcceptAutoPay}
    />
  )
}

export {MembershipApprovedTileContainer}
