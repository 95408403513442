import React, {FC, ReactNode} from 'react'
import {ErrorBoundary} from 'react-error-boundary'

import Log from 'src/lib/loggingUtil'
import {BrazeContentCardScreen} from 'src/lib/braze/braze.utils'
import {BrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/BrazeContentCards'
import {useBrazeContentCards} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards'
import {
  filterBrazeContentCardsByScreenAndLocation,
  FilterBrazeContentCardsByScreenAndLocationProps,
} from 'src/products/general/components/organisms/BrazeContentCards/useBrazeContentCards/useBrazeContentCards.utils'

const brazeAccountManagementLocation1 = filterBrazeContentCardsByScreenAndLocation({
  location: 1,
  screenName: BrazeContentCardScreen.AccountManagement,
})

// ContentCards lets us use the Braze tool to show dynamic content to users
const AccountManagementBrazeTiles: FC = (): ReactNode => {
  const {brazeContentCards, handleOnDismiss} =
    useBrazeContentCards<FilterBrazeContentCardsByScreenAndLocationProps>(
      brazeAccountManagementLocation1,
    )

  if (brazeContentCards.length > 0) {
    return (
      <ErrorBoundary
        fallback={null}
        onError={(err: Error): void => {
          Log.error(
            err,
            'AccountManagementV2 - Braze Content Cards failed to render on Account Management',
          )
        }}
      >
        <BrazeContentCards brazeContentCardData={brazeContentCards} onDismiss={handleOnDismiss} />
      </ErrorBoundary>
    )
  }

  return null
}

export {AccountManagementBrazeTiles}
