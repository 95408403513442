import React, {FC, useCallback, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Log from 'src/lib/loggingUtil'
import {AcceptAutoPayAgreement} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/AcceptAutoPayAgreement'
import {
  PaymentInfoTile,
  PaymentInfoTileProps,
} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/PaymentInfoTile'
import {MembershipTile} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/MembershipTile'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CashAdvanceEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {MembershipStartDateTile} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/MembershipStartDateTile'
import {PaymentDisclosure} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/PaymentDisclosure'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'

export type AcceptAdvanceAutoPayAgreementsTemplateProps = {
  amountApproved: string | undefined
  paymentInfo: PaymentInfoTileProps
  onAcceptAutoPay: () => Promise<void>
  loanId: string
}

const AcceptAdvanceAutoPayAgreementsTemplate: FC<AcceptAdvanceAutoPayAgreementsTemplateProps> = (
  props,
) => {
  const {onAcceptAutoPay: onAcceptAutoPay, loanId, amountApproved, paymentInfo} = props
  const {t} = useTranslation(['Advance', 'Common'])
  const [isBusy, setIsBusy] = useState(false)
  const [shouldShowAutoPayAgreement, setShouldShowAutoPayAgreement] = useState(false)
  const [isSelected, setIsSelected] = useState(false)

  usePageViewedAnalytics({
    eventName: CashAdvanceEvents.advance_schedule_viewed,
    eventCategory: AppEvents.Category.CashAdvance,
  })

  const handleContinue = useCallback(async () => {
    try {
      setIsBusy(true)
      TrackAppEvent(CashAdvanceEvents.advance_schedule_completed, AppEvents.Category.CashAdvance)
      await onAcceptAutoPay()
      setShouldShowAutoPayAgreement(false)
    } catch (e) {
      Log.error(e, 'AcceptAdvanceAutoPayAgreementsTemplate exception : ')
    } finally {
      setIsBusy(false)
    }
  }, [onAcceptAutoPay])

  const buttonLockupProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: 'Continue',
      testID: 'Accept-Advance-Auto-Pay-Agreement-Continue-Button',
      onPress: () => setShouldShowAutoPayAgreement(true),
    },
  }

  const pageDescription = (
    <PFText variant={'p'}>
      <Trans
        t={t}
        i18nKey="ReschedulePaymentDate"
        components={{bold: <PFText variant="p_semibold" />}}
      />
    </PFText>
  )

  return (
    <Page
      title={t('YourAdvanceSchedule')}
      titleTextAlignment="left"
      description={pageDescription}
      buttonProps={buttonLockupProps}
      variant={'generic'}
      smallTopGap
    >
      <Box direction={'column'} gap={'small'}>
        <MembershipTile amountApproved={amountApproved} />
        <PaymentInfoTile {...paymentInfo} />
        <MembershipStartDateTile startDate={paymentInfo?.payments[0]?.date} />
        <PaymentDisclosure />
      </Box>
      <BottomSheet
        visible={shouldShowAutoPayAgreement}
        onDismiss={async () => {
          await handleContinue()
        }}
        dismissButton={{
          text: t('Common:AcceptAndContinue'),
          testID: 'Membership-Approved-Tile-Accept-Agreement-Button',
          disabled: !isSelected || isBusy,
          loading: isBusy,
        }}
        showCloseButton
        closeButtonAction={() => setShouldShowAutoPayAgreement(false)}
        title={t('Common:AcceptAgreement')}
        titleVariant={'h3'}
        titleAlign={'left'}
        showDots={false}
      >
        <AcceptAutoPayAgreement
          handleSetSelected={setIsSelected}
          defaultPaymentMethodMask={paymentInfo?.accountMask}
          loanId={loanId}
        />
      </BottomSheet>
    </Page>
  )
}

export {AcceptAdvanceAutoPayAgreementsTemplate}
