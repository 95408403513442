import type {
  ContentCard as RnContentCard,
  ContentCardBase as RnContentCardBase,
} from '@braze/react-native-sdk'
import Log from 'src/lib/loggingUtil'

/**
 * App screens by name that are configured to render at least one `BrazeContentCardLocation`.
 */
enum BrazeContentCardScreen {
  AccountManagement = 'AccountManagement',
  LoansDashboard = 'LoansDashboard',
  CardDashboard = 'CardDashboard',
}

const brazeContentCardLocationRange = [1, 2, 3] as const
/**
 * Numerical representation of a "location" on a `BrazeContentCardScreen` from top to bottom that
 * is configured to render Braze Content Cards.
 */
type BrazeContentCardLocation = (typeof brazeContentCardLocationRange)[number]

/**
 * Custom component names for rendering Braze Content Cards.
 */
enum BrazeContentCardComponent {
  SpotlightTile = 'SpotlightTile',
}

/* `RnContentCard` is a union type so we have to use a distributive conditional type to `Omit` a
 * single property from each "member" of the union. */
type OmitExtras<T = RnContentCard> = T extends RnContentCardBase ? Omit<T, 'extras'> : never

/**
 * A more strictly typed `BrazeContentCard` used to enforce our required schema. If you are
 * interacting with Braze Content Cards outside of `/lib/braze` this is the type you should use.
 */
type BrazeContentCard = OmitExtras & {
  extras: {
    location: BrazeContentCardLocation
    screen: BrazeContentCardScreen
    component?: BrazeContentCardComponent
    ctaText?: string
    endnote?: string
    order?: number
    tags?: string[]
  } & Partial<Record<string, string | string[] | number>>
}

const isValidBrazeContentCardScreen = (
  looselyTypedScreen: string,
): looselyTypedScreen is BrazeContentCardScreen =>
  Object.values(BrazeContentCardScreen).includes(looselyTypedScreen as BrazeContentCardScreen)

const isValidBrazeContentCardLocation = (
  looselyTypedLocation: number,
): looselyTypedLocation is BrazeContentCardLocation =>
  brazeContentCardLocationRange.includes(looselyTypedLocation as BrazeContentCardLocation)

const isValidBrazeContentCardComponent = (
  looselyTypedComponentName: string,
): looselyTypedComponentName is BrazeContentCardComponent =>
  Object.values(BrazeContentCardComponent).includes(
    looselyTypedComponentName as BrazeContentCardComponent,
  )

/**
 * Filters out any Braze Content Cards that do no have our required schema (`screen`,`location`)
 * and returns a type safe array of `BrazeContentCard` objects.
 */
const getValidBrazeContentCards = (looselyTypedContentCards: RnContentCard[]): BrazeContentCard[] =>
  looselyTypedContentCards.reduce<BrazeContentCard[]>((previousValue, currentValue) => {
    /* The default `locationId` value (`??`) does not represent a default
     * `brazeContentCardLocationRange` value. It's simply to protect against `Number`
     * returning `NaN` */
    const locationId = Number(currentValue.extras?.location) || 0
    const screen = currentValue.extras?.screen ?? ''
    const component =
      currentValue.extras?.component &&
      isValidBrazeContentCardComponent(currentValue.extras.component)
        ? currentValue.extras?.component
        : undefined

    if (isValidBrazeContentCardScreen(screen) && isValidBrazeContentCardLocation(locationId)) {
      previousValue.push({
        ...currentValue,
        extras: {
          ...currentValue.extras,
          location: locationId,
          screen: screen,
          component: component,
          order: Number(currentValue.extras?.order) || undefined,
          tags: currentValue.extras.tags?.split(','),
        },
      })
    } else {
      Log.error(`Invalid Braze Content Card schema detected: ${JSON.stringify(currentValue)}`)
    }

    return previousValue
  }, [])

export {BrazeContentCardScreen, BrazeContentCardComponent, getValidBrazeContentCards}
export type {BrazeContentCard}
