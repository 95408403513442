import React from 'react'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {StateDropdown} from 'src/designSystem/components/molecules/StateDropdown/StateDropdown'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {StateCodes, UserIdInfoType} from 'src/cassandra'
import {
  CollectIDStateNVFormType,
  CollectIDStateNVTemplateProps,
} from 'src/products/general/CollectIDStateNV/CollectIDStateNV.types'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {PFDropdown} from 'src/designSystem/components/atoms/PFDropdown/PFDropdown'
import List from 'src/designSystem/components/molecules/List/List'
import {SessionReplay} from 'src/lib/sessionReplay/sessionReplay'

export const CollectIDStateNVTemplate = (props: CollectIDStateNVTemplateProps): JSX.Element => {
  const {onSubmit, isLoading} = props

  const {t} = useTranslation(['CollectIDStateNV', 'Common'])

  const idTypeOptions = Object.keys(UserIdInfoType).map((idType) => ({
    label: t(`IdType${idType}`),
    value: String(UserIdInfoType[idType]),
  }))

  const formContext = useForm<CollectIDStateNVFormType>({
    defaultValues: {
      idNumber: '',
      idType: UserIdInfoType.DriversLicense,
      stateWhereIssued: StateCodes.Nv,
    },
    mode: 'all',
  })

  const {
    formState: {errors, isValid},
    watch,
    control,
  } = formContext

  const handleOnSubmit = (): void => {
    void formContext.handleSubmit(onSubmit)?.()
  }

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      testID: 'CollectIDStateNV-SubmitButton',
      text: t('Common:Submit'),
      loading: isLoading,
      disabled: !isValid,
      onPress: handleOnSubmit,
    },
  }

  return (
    <Page
      testID="CollectIDStateNVTemplate"
      variant="generic"
      noHeaderSpacer
      buttonProps={buttonProps}
      title={t('CollectIDStateNVTitle')}
    >
      <FormProvider {...formContext}>
        <Box gap={'small'}>
          <List
            variant="icon"
            items={[
              {
                title: t('CollectIDStateNVInfoTitle1'),
                text: t('CollectIDStateNVInfoBody1'),
                name: 'info',
                colorVariant: 'success',
              },
              {
                title: t('CollectIDStateNVInfoTitle2'),
                text: t('CollectIDStateNVInfoBody2'),
                name: 'checkWithCircle',
                colorVariant: 'success',
              },
            ]}
          />
          <Controller
            name="idType"
            control={control}
            rules={{required: t('Common:LabelIsRequired', {label: t('CollectIDType')})}}
            render={({field: {onChange: handleOnChange}}) => (
              <PFDropdown
                label={t('CollectIDType')}
                testID="IDType-Dropdown"
                onSelection={handleOnChange}
                error={errors.idType?.message}
                value={watch('idType')}
                options={idTypeOptions}
                placeholder={t('CollectIDTypePlaceholder')}
                shouldUseValueForLabel={false}
              />
            )}
          />
          <Controller
            name="idNumber"
            control={control}
            rules={{
              required: t('Common:LabelIsRequired', {replace: {label: t('CollectIDNumber')}}),
              minLength: {
                value: 4,
                message: t('ErrorIDNumberMinLength'),
              },
            }}
            render={({field: {onChange: handleOnChange, value}}) => (
              <PFTextInput
                label={t('CollectIDNumber')}
                testID="IDNumber-TextField"
                error={errors.idNumber?.message}
                onChangeText={handleOnChange}
                value={value}
                {...SessionReplay.privacyProps()}
              />
            )}
          />
          <Controller
            name="stateWhereIssued"
            control={control}
            rules={{required: t('Common:LabelIsRequired', {label: t('CollectIDStateWhereIssued')})}}
            render={({field: {onChange: handleOnChange}}) => (
              <StateDropdown
                label={t('CollectIDStateWhereIssued')}
                otherOptions={[{label: t('IdTypeOther'), value: StateCodes.Any}]}
                useValueForLabel={true}
                error={errors.stateWhereIssued?.message}
                testID="Issued-State-Dropdown"
                value={watch('stateWhereIssued')}
                onSelection={handleOnChange}
              />
            )}
          />
        </Box>
      </FormProvider>
    </Page>
  )
}
