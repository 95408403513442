import React, {FC, ReactNode, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {littleGap, smallGap} from 'src/designSystem/layout'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {
  BankAccountInfo,
  convertAchPaymentMethodToBankAccountInfo,
  convertLinkedAccountToBankAccountInfo,
} from 'src/products/MCU/AccountManagementV2/BankAccountInfo'
import {ErrorCapsule} from 'src/products/MCU/AccountManagementV2/ErrorCapsules'
import {NoPrimaryAccountTile} from 'src/products/MCU/AccountManagementV2/NoPrimaryAccountTile'
import {LearnMoreAboutPaymentAccountsInline} from 'src/products/MCU/AccountManagementV2/PrimaryAccount/LearnMoreAboutPaymentAccountsInline'
import {AccountNotUsableInfoTile} from 'src/products/MCU/AccountManagementV2/AccountNotUsableInfoTile'
import {hasValidOwnershipStatus} from 'src/products/MCU/AccountManagementV2/AccountManagement.utils'
import {AddNewAccountModal} from 'src/products/MCU/AccountManagementV2/Modals/AddNewAccountModal'
import {BankAccountTile} from 'src/products/MCU/AccountManagementV2/BankAccountTile/BankAccountTile'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {NamedColors} from 'src/designSystem/colors'
import {DebitCardTile} from 'src/products/MCU/AccountManagementV2/DebitCardTile/DebitCardTile'
import {Alert, AlertProps} from 'src/designSystem/components/molecules/Alert/Alert'
import {
  AccountManagementLinkedAccountBank,
  AccountManagementPaymentMethodAch,
  AccountManagementPaymentMethodDebitCard,
} from 'src/products/MCU/AccountManagementV2/queries/AccountManagementAccounts/useAccountManagementAccountsQuery'
import {DebitCardTilePaymentMethod} from 'src/products/MCU/AccountManagementV2/DebitCardTile/DebitCardTile.types'
import HeaderSpacer from 'src/designSystem/components/atoms/HeaderSpacer/HeaderSpacer'
import {logErrorAndShowException} from 'src/lib/errors'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {AccountManagementBrazeTiles} from 'src/products/MCU/AccountManagementV2/AccountManagementBrazeTiles/AccountManagementBrazeTiles'

export type AccountManagementViewV2Props = {
  navigation: StackNavigationProp<MainStackParamList, 'AccountManagementV2'>
  isLoading: boolean
  debitCards: AccountManagementPaymentMethodDebitCard[] | undefined
  achPaymentMethods: AccountManagementPaymentMethodAch[] | undefined
  // id of the default payment method for their current loan if they have one
  defaultLoanPaymentMethodId?: string
  bankLinkedAccountsNeedAttention: AccountManagementLinkedAccountBank[] | undefined
  addNewAccount: () => void
  onEditBankDetails?: (accountId: string, mask: string) => void
  onChooseNewAccountComplete?: () => Promise<void>
  onRelinkAccount: (accountId: string) => void
  alerts?: AlertProps[]
  linkedAccountIdsJustAdded?: string[]
  justAddedPaymentInstrumentId?: string
  activeLoanId?: string
}

export const AccountManagementViewV2: FC<AccountManagementViewV2Props> = ({
  isLoading,
  alerts,
  linkedAccountIdsJustAdded,
  justAddedPaymentInstrumentId,
  navigation,
  debitCards,
  achPaymentMethods,
  defaultLoanPaymentMethodId,
  bankLinkedAccountsNeedAttention,
  activeLoanId,
  addNewAccount: handleOnAddNewAccount,
  onEditBankDetails: handleOnEditBankDetails,
  onRelinkAccount: handleOnRelinkAccount,
  onChooseNewAccountComplete,
}) => {
  const {t} = useTranslation('AccountManagement')
  const [shouldShowAddNewAccountModal, setShouldShowAddNewAccountModal] = useState(false)
  const isContentCardsEnabled = useIsFeatureFlagEnabled('braze-content-cards')

  // convert the achPaymentMethods to BankAccountInfo type since it's used throughout AccountManagement
  const achPaymentMethodAccounts: BankAccountInfo[] = useMemo(() => {
    const accounts: BankAccountInfo[] = []
    achPaymentMethods?.forEach((achPaymentMethod) => {
      const thisAchBankAccountInfo = convertAchPaymentMethodToBankAccountInfo({
        achPaymentMethod,
        defaultLoanPaymentMethodId,
      })
      if (thisAchBankAccountInfo) {
        accounts.push(thisAchBankAccountInfo)
      }
    })
    return accounts
  }, [achPaymentMethods, defaultLoanPaymentMethodId])

  // convert the bankLinkedAccounts to BankAccountInfo type since it's used throughout AccountManagement
  // these are only bank accounts that need attention from the user and can't be used. if they're okay
  // to be used they will be in achPaymentMethods instead
  const bankAccountsThatNeedAttention: BankAccountInfo[] = useMemo(() => {
    const accounts: BankAccountInfo[] = []
    bankLinkedAccountsNeedAttention?.forEach((bankLinkedAccount) => {
      const thisLinkedAccountInfo = convertLinkedAccountToBankAccountInfo(bankLinkedAccount)
      if (thisLinkedAccountInfo) {
        accounts.push(thisLinkedAccountInfo)
      }
    })
    return accounts
  }, [bankLinkedAccountsNeedAttention])

  const allBankAccountsAndPaymentMethods: BankAccountInfo[] = achPaymentMethodAccounts.concat(
    bankAccountsThatNeedAttention,
  )

  const accountsWithErrors: BankAccountInfo[] = useMemo(() => {
    return (
      allBankAccountsAndPaymentMethods?.filter((account: BankAccountInfo) => {
        return (
          account.isRelinkRequired ||
          (!account.isRelinkRequired && !account?.isRoutingAndAccountNumbersAvailable)
        )
      }) ?? []
    )
  }, [allBankAccountsAndPaymentMethods])

  const primaryAccount: BankAccountInfo | undefined = useMemo(() => {
    return allBankAccountsAndPaymentMethods?.find((account: BankAccountInfo) => account.primary)
  }, [allBankAccountsAndPaymentMethods])

  const additionalPaymentMethods: BankAccountInfo[] = allBankAccountsAndPaymentMethods
    .filter((account) => !account.primary)
    .map((account) => {
      const isNewLinkedAccountFromAggregator = !!linkedAccountIdsJustAdded?.find(
        (linkedAccountId) => linkedAccountId === account.id,
      )
      const isNewPaymentMethodAddedManually = account.id === justAddedPaymentInstrumentId
      const bankAccountInfo: BankAccountInfo = {
        ...account,
        // mark the ach accounts that were just added
        isAccountJustAdded: isNewLinkedAccountFromAggregator || isNewPaymentMethodAddedManually,
      }
      return bankAccountInfo
    })
  const debitCardPaymentMethods: DebitCardTilePaymentMethod[] = debitCards
    ? debitCards.map((card): DebitCardTilePaymentMethod => {
        return {
          ...card,
          // mark the debit cards that were just added
          isCardJustAdded: card.bankingPaymentInstrumentId === justAddedPaymentInstrumentId,
          isDefaultLoanPaymentMethod: card.id === defaultLoanPaymentMethodId,
        }
      })
    : []

  const onAddNewAccount = (): void => {
    navigation.push('AddNewAccountToPossible', {
      onAddNewLinkedAccount: () => {
        navigation.pop()
        handleOnAddNewAccount()
      },
    })
  }

  const hasInvalidStatus = (): boolean =>
    achPaymentMethodAccounts?.some(
      (account: BankAccountInfo) =>
        !hasValidOwnershipStatus(account) || !account.isRoutingAndAccountNumbersAvailable,
    ) || false

  const handleNavigateToPrimaryAccountInfo = (): void => {
    navigation.navigate('AccountsWithPossible')
  }

  const handleNavigateToSetAccountAsPrimary = (account: BankAccountInfo): void => {
    navigation.navigate('SetAccountAsPrimary', {
      accountMask: account.mask,
      accountId: account.id,
      onChooseNewAccountComplete,
    })
  }

  const handleNavigateToSetLoanAutoPayAccount = ({
    paymentMethodId,
  }: {
    paymentMethodId: string
  }): void => {
    if (!paymentMethodId || !activeLoanId) {
      void logErrorAndShowException(
        new Error(
          `AccountManagementV2 could not navigate to SetLoanAutoPayAccount, paymentMethodId or loanId missing. activeLoanId=${activeLoanId} paymentMethodId=${paymentMethodId}`,
        ),
      )
      return
    }
    navigation.push('SetLoanAutoPayAccount', {
      loanId: activeLoanId,
      paymentMethodId: paymentMethodId,
      onSuccessRoute: 'AccountManagementV2',
    })
  }

  const handleNavigateToAddPrimaryAccount = (): void => {
    const shouldLinkNewAccount = achPaymentMethodAccounts.length === 0
    navigation.navigate('AddPrimaryAccount', {shouldLinkNewAccount, onChooseNewAccountComplete})
  }

  const primaryAccountSectionTitle = (
    <PFText
      color={NamedColors.BLACK}
      variant={'p_semibold'}
      testID="Primary-Account-Title"
      onPress={handleNavigateToPrimaryAccountInfo}
    >
      {t('PrimaryAccount')}
    </PFText>
  )

  const paymentsMethodsSectionTitle = (
    <PFText
      color={NamedColors.BLACK}
      variant={'p_semibold'}
      testID="Additional-Payment-Methods-Title"
    >
      {t('AdditionalPaymentMethods')}
    </PFText>
  )

  const getPrimaryAccountTile = (): ReactNode => {
    return primaryAccount ? (
      <BankAccountTile
        onNavigateToSetLoanAutoPayAccount={handleNavigateToSetLoanAutoPayAccount}
        account={primaryAccount}
        onRelinkAccount={handleOnRelinkAccount}
        onAddNewAccount={handleOnAddNewAccount}
        activeLoanId={activeLoanId}
      />
    ) : null
  }
  // combine list of paymentMethods with achPaymentMethods from the query
  const getAdditionalPaymentMethodTiles = additionalPaymentMethods.map((account) => {
    return (
      <Box key={account.id} marginBottom={smallGap}>
        <BankAccountTile
          account={account}
          onEditBankDetails={handleOnEditBankDetails}
          onRelinkAccount={handleOnRelinkAccount}
          onAddNewAccount={handleOnAddNewAccount}
          onNavigateToSetLoanAutoPayAccount={handleNavigateToSetLoanAutoPayAccount}
          activeLoanId={activeLoanId}
          onNavigateToSetAccountAsPrimary={handleNavigateToSetAccountAsPrimary}
        />
      </Box>
    )
  })

  const getDebitCardTiles = debitCardPaymentMethods
    ? debitCardPaymentMethods.map((card) => {
        return (
          <Box key={card.id} marginBottom={smallGap}>
            <DebitCardTile debitPaymentMethod={card} />
          </Box>
        )
      })
    : null

  const handleOnAddNewAccountModalDismiss = (): void => setShouldShowAddNewAccountModal(false)

  const title = (
    <Box width={'100%'}>
      {alerts ? (
        <Box marginBottom={smallGap}>
          {alerts.map((alert) => (
            <Alert key={alert.title} {...alert} />
          ))}
        </Box>
      ) : null}
      <PFText>{t('AccountManagementV2')}</PFText>
    </Box>
  )

  const brazeContentCards = isContentCardsEnabled ? (
    <Box marginBottom={littleGap}>
      <AccountManagementBrazeTiles />
    </Box>
  ) : null

  return (
    <>
      <Page
        variant="generic"
        title={
          <>
            {/*
              the default page header spacer doesnt look right when this page is embedded in a ShowLightbox modal
              for URAs so we add it manually here instead and set noHeaderSpacer={true}. see ENG-17521
            */}
            <HeaderSpacer />
            {title}
          </>
        }
        smallTopGap
        noHeaderSpacer={true}
        buttonProps={{
          type: 'singleButton',
          primary: {
            text: t('AddNewAccount'),
            onPress: onAddNewAccount,
            testID: 'add-new-account-button',
          },
        }}
      >
        {accountsWithErrors.length > 0 ? (
          <ErrorCapsule errorsCount={accountsWithErrors.length} />
        ) : null}
        <Box>
          <Box marginBottom={littleGap}>{primaryAccountSectionTitle}</Box>
          <Box>
            {!isLoading && !primaryAccount && achPaymentMethodAccounts ? (
              <NoPrimaryAccountTile onPress={handleNavigateToAddPrimaryAccount} />
            ) : null}
            {getPrimaryAccountTile()}
            <Box marginVertical={smallGap}>
              <LearnMoreAboutPaymentAccountsInline />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box marginBottom={littleGap}>{paymentsMethodsSectionTitle}</Box>
          {getAdditionalPaymentMethodTiles}
          {getDebitCardTiles}
        </Box>
        {hasInvalidStatus() ? <AccountNotUsableInfoTile accountManagementScreen /> : null}
        {brazeContentCards}
      </Page>
      <AddNewAccountModal
        onOk={handleOnAddNewAccount}
        visible={shouldShowAddNewAccountModal}
        onDismiss={handleOnAddNewAccountModalDismiss}
      />
    </>
  )
}
